import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { ShipmentInputs } from '../../model/shipment-inputs-model';
import { ErrorUtil } from '../../utils/error.util';
import { API_CORE } from '../../constant/api-core.const';

@Injectable({
  providedIn: 'root',
})
export class AutofillDimensionsService {
  async extractShipmentInputs(
    data: any,
    type: string,
  ): Promise<ShipmentInputs> {
    if (type === 'SIMPLE_TEXT') {
      data = btoa(data);
    }
    return API.post(
      API_CORE.name,
      API_CORE.URLs.Solutions.ExtractShipmentInputs,
      {
        body: { data, type },
      },
    ).catch(ErrorUtil.handleError);
  }

  async getShipmentInputsByUuid(uuid: string): Promise<ShipmentInputs> {
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Solutions.GetShipmentInputsByUuid.replace('{uuid}', uuid),
      {},
    ).catch(ErrorUtil.handleError);
  }
}
