<div class="modal-body office-settings-danger-modal">
  <button class="close" (click)="modalRef.dismiss()">&times;</button>
  <form class="office-settings-modal-form">
    <h2 class="header modal-title">
      <span i18n="@@office-setting.remove-user">Remove User</span>
    </h2>
    <div class="body">
      <p>
        <span i18n="@@global.sure-remove">Are you sure you want to remove </span>
        <b>{{ userToRemove.firstName }} {{ userToRemove.lastName }} </b>
        <span i18n="@@global.from">from </span>
        <b>{{ activeOffice.name ?? getLongOfficeName(activeOffice) }}</b>?
      </p>
      <p i18n="@@office-remove-user-modal.transfer-quotes" *ngIf="!isWalletApp">
        All Quotes/Bookings of this user will be transferred to you.
      </p>
      <p i18n="@@office-remove-user-modal.orphan-user" *ngIf="isLastOfficeOfUser"
        class="kt-font-danger"
      >
        User has no access to other offices.  User will be <b>disabled</b> after this operation.
      </p>
    </div>
    <div class="d-flex justify-content-center w-100" style="gap: 10px">
      <button
        class="btn btn-secondary"
        type="button"
        (click)="modalRef.dismiss()"
        i18n="@@global.cancel"
      >
        Cancel
      </button>
      <button
        class="btn {{ isLastOfficeOfUser ? 'btn-danger' : 'btn-primary' }}"
        type="button"
        (click)="removeUserFromOffice()"
        i18n="@@office-remove-user-modal.remove"
      >
        Remove<span *ngIf="isLastOfficeOfUser"> and Disable</span>
      </button>
    </div>
  </form>
</div>
