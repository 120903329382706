<div class="modal-body add-user-to-office-modal">
  <button class="close" (click)="modalRef.dismiss()">&times;</button>
  <form
    class="office-settings-modal-form office-settings-form"
    [formGroup]="addUserToOfficeForm"
    (ngSubmit)="onSubmitForm()"
  >
    <h2 class="header modal-title">
      <span i18n="@@office-setting.add-user">Add User</span>
    </h2>
    <mat-form-field appearance="outline">
      <mat-label i18n="@@global.email">Email</mat-label>
      <input
        type="text"
        class="input-field"
        aria-label="Email"
        matInput
        placeholder="Email"
        formControlName="email"
      />
      <mat-error *ngIf="isControlHasError('email', 'required')">
        <strong i18n="@@office-setting.email-required-field">
          User email is required
        </strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'email')">
        <strong i18n="@@global.invalid-email"> Invalid Email </strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'noSuchUser')">
        <strong i18n="@@office-setting.no-such-user">
          Email does not exist in your company
        </strong>
      </mat-error>
      <mat-error *ngIf="isControlHasError('email', 'partOfOffice')">
        <strong i18n="@@office-setting.part-of-office">
          User has already been added
        </strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label i18n="@@office-setting.roles">Roles</mat-label>
      <mat-select
        formControlName="roles"
        multiple
        panelClass="office-settings-panel"
      >
        <mat-option
          *ngFor="let role of relevantRoles"
          [value]="role"
          class="office-settings-select-option"
        >
          {{ role }}
          <img
            *ngIf="role !== 'STANDARD'"
            class="protag-img"
            src="/assets/media/icons/pro-tag.svg"
            alt=""
          />
        </mat-option>
      </mat-select>
      <img
        *ngIf="displayProTag"
        class="protag-img"
        src="/assets/media/icons/pro-tag.svg"
        alt=""
      />

      <mat-error *ngIf="isControlHasError('roles', 'minItems')">
        <strong i18n="@@office-setting.at-least-one-role-required-field">
          At least one role is required
        </strong>
      </mat-error>
    </mat-form-field>

    <div class="d-flex justify-content-center w-100" style="gap: 10px">
      <button
        class="btn btn-secondary"
        type="button"
        (click)="modalRef.dismiss()"
        i18n="@@global.cancel"
      >
        Cancel
      </button>
      <button class="btn btn-primary" type="submit" i18n="@@global.confirm">
        Confirm
      </button>
    </div>
  </form>
</div>
