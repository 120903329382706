import { Component, Input, Optional } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  "selector": "kt-basic-popup",
  "templateUrl": "./basic-popup.component.html",
  "styleUrls": ["./basic-popup.component.scss"],
})
export class BasicPopupComponent {
  @Input() header: string;
  @Input() body: string;
  @Input() footer: string;
  @Input() primaryButtonText: string;
  @Input() hideCancel: boolean;
  @Input() hideClose: boolean;
  @Input() primaryButtonColor: string;
  @Input() secondaryButtonColor: string;
  @Input() titleColor: string;
  @Input() closeButtonColor: string;

  constructor (
    @Optional()
    public activeModal: NgbActiveModal
  ) {}

  handleClick () {
    this.activeModal.dismiss();
  }

  cancel () {
    this.activeModal.close({ "cancel": true });
  }
}
