<div class="quality-score-container">
  <div class="quality-score-svg">
    <img class="protag-img" src="/assets/media/icons/pro-tag.svg" alt="" />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.85141 0.271688C6.52441 0.0934125 6.15791 0 5.78547 0C5.41303 0 5.04653 0.0934125 4.71953 0.271688L1.16021 2.21205C0.809025 2.40369 0.515949 2.68639 0.31179 3.03044C0.10763 3.3745 -7.40105e-05 3.7672 3.81568e-08 4.16727V7.83273C-7.40105e-05 8.2328 0.10763 8.6255 0.31179 8.96956C0.515949 9.31361 0.809025 9.59631 1.16021 9.78795L4.71953 11.7283C5.04653 11.9066 5.41303 12 5.78547 12C6.15791 12 6.52441 11.9066 6.85141 11.7283L10.41 9.78795C10.7613 9.59641 11.0545 9.31375 11.2588 8.96969C11.4631 8.62563 11.5709 8.23288 11.5709 7.83273V4.16652C11.5709 3.76638 11.4631 3.37363 11.2588 3.02957C11.0545 2.68551 10.7613 2.40285 10.41 2.21131L6.85141 0.271688ZM5.78547 3.77311C5.57466 3.77311 5.43362 4.02549 5.15155 4.53248L5.0788 4.66312C4.99863 4.80713 4.95855 4.87839 4.8962 4.9259C4.8331 4.9734 4.75516 4.99122 4.59928 5.02611L4.45824 5.05877C3.91042 5.18273 3.63652 5.24434 3.57119 5.45367C3.50587 5.66374 3.69293 5.88197 4.06631 6.31845L4.16281 6.43128C4.26895 6.55524 4.3224 6.61685 4.34615 6.69405C4.36991 6.77125 4.36174 6.85364 4.34615 7.01918L4.33131 7.16986C4.27489 7.75256 4.24669 8.04429 4.41667 8.17345C4.5874 8.30261 4.84424 8.18458 5.35716 7.94853L5.48929 7.88766C5.63552 7.82086 5.70827 7.78745 5.78547 7.78745C5.86267 7.78745 5.93541 7.82086 6.08165 7.88766L6.21377 7.94853C6.7267 8.18532 6.98354 8.30261 7.15427 8.17345C7.325 8.04429 7.29604 7.75256 7.23963 7.16986L7.22478 7.01918C7.2092 6.85364 7.20103 6.77125 7.22478 6.69405C7.24854 6.61759 7.30198 6.55524 7.40813 6.43128L7.50463 6.31845C7.87801 5.88197 8.06507 5.66374 7.99974 5.45367C7.93442 5.24434 7.66051 5.18273 7.1127 5.05877L6.97166 5.02611C6.81578 4.99122 6.73784 4.97414 6.67474 4.9259C6.61239 4.87839 6.5723 4.80713 6.49214 4.66312L6.41939 4.53248C6.13732 4.02623 5.99628 3.77311 5.78547 3.77311Z"
        fill="#61C7C6"
      />
    </svg>
  </div>
  <div class="quality-score-text-container">
    <div class="quality-score-text" *ngIf="qualityScore">
      Route Quality: {{ qualityScore }}%
    </div>
    <div class="quality-score-text" *ngIf="avgQualityScore">
      Airline Quality: {{ avgQualityScore }}%
    </div>
  </div>
</div>
