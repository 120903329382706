import { AppNotificationTypeEnum } from '@cai-services';

const defaultNotificationSettingsForwarder = [
    {
      id: 1,
      category: $localize`:@@notification-category.quote:Quote`,
      settings: [
        {
          name: AppNotificationTypeEnum.QUOTE_ACCEPT,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.quote-accept-airline:Quote 'accepted' by Airline`,
        },
        {
          name: AppNotificationTypeEnum.QUOTE_REJECT,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.quote-reject-airline:Quote 'rejected' by Airline`,
        },
      ],
    },
    {
      id: 2,
      category: $localize`:@@notification-category.booking:Booking`,
      settings: [
        {
          name: AppNotificationTypeEnum.BOOKING_REQUESTED_STILL_PENDING,
          notificationType: null,
          isSendEmail: true,
          isSendToast: false,
          label: $localize`:@@notification.booking-request-sent-to-airline:Booking request sent to Airline`,
          isPushNotApplicable: true,
        },
        {
          name: AppNotificationTypeEnum.AIRLINE_BOOKING_CONFIRMATION,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.booking-confirm-airline:Booking 'confirmed' by Airline`,
        },
        {
          name: AppNotificationTypeEnum.OPTION_PLACED,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.booking-option-placed:Booking Option placed by you`,
        },
        {
          name: AppNotificationTypeEnum.BOOKING_REJECT,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.booking-reject-airline:Booking 'rejected' by Airline`,
        },
        {
          name: AppNotificationTypeEnum.BOOKING_CANCEL,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.booking-cancel-airline:Booking 'cancelled' by Airline`,
        },
        {
          name: AppNotificationTypeEnum.OPTION_CANCEL,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.booking-option-cancel:Booking Option cancelled`,
        },
        {
          name: AppNotificationTypeEnum.BOOKING_MODIFY_ACCEPT,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.booking-modify-request-accept:Booking modification accepted by Airline`,
        },
        {
          name: AppNotificationTypeEnum.BOOKING_MODIFY_REJECT,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.booking-modify-request-reject:Booking modification rejected by Airline`,
        },
      ],
    },
    {
      id: 3,
      category: $localize`:@@notification-category.shipment-updates:Shipment Updates`,
      settings: [
        {
          name: AppNotificationTypeEnum.RATE_UPDATE,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.rate-updated:Rate has been updated by Airline`,
        },
        {
          name: AppNotificationTypeEnum.DEPARTURE_UPDATE,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.departure-updated:Departure date has been updated by Airline`,
        },
        {
          name: AppNotificationTypeEnum.ARRIVAL_UPDATE,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.arrival-updated:Arrival date has been updated by Airline`,
        },
        {
          name: AppNotificationTypeEnum.AIRCRAFT_UPDATE,
          notificationType: null,
          isSendEmail: false,
          isSendToast: false,
          label: $localize`:@@notification.aircraft-type-changed:Aircraft type has been changed by Airline`,
        },
        {
          name: AppNotificationTypeEnum.FLIGHT_NUMBER_UPDATE,
          notificationType: null,
          isSendEmail: false,
          isSendToast: true,
          label: $localize`:@@notification.flight-number-changed:Flight number has been changed`,
        },
        {
          name: AppNotificationTypeEnum.ORIGIN_UPDATE,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.departure-airport-changed:Departure Airport has been changed`,
        },
        {
          name: AppNotificationTypeEnum.DESTINATION_UPDATE,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.arrival-airport-changed:Arrival airport has been changed`,
        },
        {
          name: AppNotificationTypeEnum.QUOTE_VALIDITY_UPDATE,
          notificationType: null,
          isSendEmail: false,
          isSendToast: false,
          label: $localize`:@@notification.quote-validity-updated:Quote Validity has been updated by Airline`,
        },
        {
          name: AppNotificationTypeEnum.WEIGHT_VOLUME_PIECES_UPDATE,
          notificationType: null,
          isSendEmail: false,
          isSendToast: true,
          label: $localize`:@@notification.weight-volume-pieces-updated:Total gross weight/volume/pieces have been updated by Airline `,
        },
        {
          name: AppNotificationTypeEnum.CHARGEABLE_WEIGHT_UPDATE,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.chargeable-weight-updated:Chargeable weight has been changed by Airline`,
        },
        {
          name: AppNotificationTypeEnum.ULD_UPDATE,
          notificationType: null,
          isSendEmail: false,
          isSendToast: true,
          label: $localize`:@@notification.uld-updated:ULD has been updated  by Airline`,
        },
        {
          name: AppNotificationTypeEnum.ALLOTMENT_UPDATE,
          notificationType: null,
          isSendEmail: false,
          isSendToast: true,
          label: $localize`:@@notification.allotment-updated:Allotment ID has been updated  by Airline`,
        },
        {
          name: AppNotificationTypeEnum.DIMENSIONS_UPDATE,
          notificationType: null,
          isSendEmail: false,
          isSendToast: true,
          label: $localize`:@@notification.dimesnions-updated:Dimensions have been updated  by Airline`,
        },
      ],
    },
    {
      id: 4,
      category: $localize`:@@notification-category.track-and-trace:Track & Trace`,
      settings: [
        {
          name: AppNotificationTypeEnum.QUOTE_IN_TRANSIT,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.shipment-in-transit:Shipment 'in transit'`,
        },
        {
          name: AppNotificationTypeEnum.QUOTE_AT_DESTINATION,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.shipment-in-transit:Shipment 'at destination'`,
        },
        {
          name: AppNotificationTypeEnum.QUOTE_DELIVERED,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.shipment-delivered:Shipment 'delivered'`,
        },
      ],
    },
    {
      id: 5,
      category: $localize`:@@notification-category.chat:Chat`,
      settings: [
        {
          name: AppNotificationTypeEnum.QUOTE_CHAT_MESSAGE,
          notificationType: null,
          isSendEmail: false,
          isSendPush: true,
          label: $localize`:@@notification-category.chat-message-from-airline:New chat message from Airline`,
        },
      ],
    },
    {
      id: 6,
      category: $localize`:@@notification-category.insights:Insights`,
      settings: [
        {
          name: AppNotificationTypeEnum.RATE_AND_CAPACITY_DATA,
          notificationType: null,
          isSendEmail: true,
          isSendToast: false,
          label: $localize`:@@notification-category.rate-and-capacity-data: Rate and Capacity Data`,
          isPushNotApplicable: true,
        },
      ],
    },
    {
      id: 7,
      category: $localize`:@@notification-category.search-popup:Search Popup`,
      settings: [
        {
          name: AppNotificationTypeEnum.SEARCH_POPUP,
          notificationType: null,
          isSendEmail: false,
          isSendToast: true,
          label: $localize`:@@notification.search-popup:Display search popup`,
          isEmailNotApplicable: true,
        },
      ],
    },
  ],
  defaultNotificationSettingsAirline = [
    {
      id: 8,
      category: $localize`:@@notification-category.quote:Quote`,
      settings: [
        {
          name: AppNotificationTypeEnum.QUOTE_REQUEST,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.quote-request-forwarder:Quote 'requested' by Forwarder`,
        },
      ],
    },
    {
      id: 9,
      category: $localize`:@@notification-category.booking:Booking`,
      settings: [
        {
          name: AppNotificationTypeEnum.BOOKING_REQUEST,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.booking-request-forwarder:Booking 'requested' by Forwarder`,
        },
        {
          name: AppNotificationTypeEnum.BOOKING_CANCEL,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.booking-cancel-forwarder:Booking cancelled by Forwarder`,
        },
        {
          name: AppNotificationTypeEnum.BOOKING_MODIFY_REQUEST,
          notificationType: null,
          isSendEmail: true,
          isSendToast: true,
          label: $localize`:@@notification.booking-modify-request:Booking modification requested by Forwarder`,
        },
      ],
    },
    {
      id: 10,
      category: $localize`:@@notification-category.chat:Chat`,
      settings: [
        {
          name: AppNotificationTypeEnum.QUOTE_CHAT_MESSAGE,
          notificationType: null,
          isSendEmail: false,
          isSendToast: true,
          label: $localize`:@@notification.chat-message-forwarder:New chat message from Forwarder`,
        },
      ],
    },
  ];

export {
  defaultNotificationSettingsAirline,
  defaultNotificationSettingsForwarder,
};
