import { Injectable } from '@angular/core';
import {
  IAirlineRate,
  IGha,
  IPaginationResults,
  IRateAirlineProdCode,
  IRateInsight,
  IRateMetadata,
  RateService,
} from '@cai-services';
import { Observable, defer, from } from 'rxjs';
import { BlobFile } from '../_models/blob-file.model';

@Injectable({
  providedIn: 'root',
})
export class AppRateService {
  constructor(private readonly rateService: RateService) {}

  /**
   * Retrieve all rates
   * @param page
   * @param size
   * @param sort
   * @param origin
   * @param airline
   * @param filterHistory
   * @param country
   * @param filterOptions
   * @returns
   */
  async compareRates(
    page: number,
    size: number,
    sort: string,
    origin?: string,
    airline?: string,
    selectedOfficeId?: number,
    country?: string,
    filterHistory?: boolean,
    filterOptions?: { [key: string]: string },
    search?: string,
  ): Promise<IPaginationResults<IAirlineRate>> {
    return this.rateService.getAllRates(
      page,
      size,
      sort,
      filterOptions?.origin || origin,
      airline,
      selectedOfficeId,
      country,
      filterHistory,
      search,
      filterOptions?.commodity,
      filterOptions?.destination,
      filterOptions?.rateCategory,
      filterOptions?.service,
      filterOptions?.rateType,
      filterOptions?.iatacass,
      filterOptions?.departureDate,
    );
  }

  public exportExcel(
    sort?: string,
    origin?: string,
    airline?: string,
    selectedOfficeId?: number,
    country?: string,
    filterOptions?: { [key: string]: string },
    search?: string,
  ): Observable<BlobFile> {
    return from(
      this.rateService.exportExcel(
        airline,
        selectedOfficeId,
        filterOptions?.origin || origin,
        country,
        search,
        filterOptions?.commodity,
        filterOptions?.destination,
        filterOptions?.rateCategory,
        filterOptions?.service,
        filterOptions?.rateType,
        filterOptions?.iatacass,
        filterOptions?.departureDate,
      ),
    );
  }

  public getRatesForMetadata(
    airline?: string,
    selectedOfficeId?: number,
    country?: string,
    rateCategory?: string,
    iatacass?: string,
  ): Observable<any> {
    return from(
      this.rateService.getRatesForMetadata(
        airline,
        selectedOfficeId,
        country,
        rateCategory,
        iatacass,
      ),
    );
  }

  public getRatesMetadata(
    airline?: string,
    selectedOfficeId?: number,
    country?: string,
    rateCategory?: string,
    iatacass?: string,
  ): Promise<IRateMetadata> {
    return this.rateService.getRatesForMetadata(
      airline,
      selectedOfficeId,
      country,
      rateCategory,
      iatacass,
    );
  }

  getRateInsight(
    airline: string,
    origin: string,
    destination: string,
    commodity: string,
    chargeableWeight: number,
    officeId: number,
    departureDate: string,
    originCountry?: string,
    destinationCountry?: string,
  ): Observable<IRateInsight> {
    return defer(() =>
      this.rateService.getRateInsight(
        airline,
        origin,
        destination,
        commodity,
        chargeableWeight,
        officeId,
        departureDate,
        originCountry,
        destinationCountry,
      ),
    );
  }

  getAirlineProdCodes(
    officeId: number,
    airline?: string,
  ): Promise<IRateAirlineProdCode[]> {
    return this.rateService.getAirlineProdCodes(officeId, airline);
  }

  async getGhas(
    airlineBrandCode: string,
    stationCode: string,
    officeId: number,
  ): Promise<IGha[]> {
    return this.rateService.getGhas(airlineBrandCode, stationCode, officeId);
  }
}
