import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { EawbDetailsWithError, IEawbDetails, IFhlDetails } from '@cai-services';
import { API_CORE } from '../../constant/api-core.const';
import {
  MetadataTypeEnum,
  ObjectReturnModeEnum,
  QuoteRequestStatusEnum,
  QuoteStatusEnum,
} from '../../enum';
import {
  IDocumentExport,
  ILightQuote,
  IMessage,
  IPaginationResults,
  IPresignedURL,
  IQuote,
  IQuoteAttachment,
  IQuoteMetadata,
  IQuoteWithErrors,
} from '../../model';
import { IPresignedQuoteAttachment } from '../../model/presigned-quote-attachment.model';
import { ErrorUtil } from '../../utils/error.util';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  async getQuotes(
    page: number,
    size: number,
    sort: string | string[],
    type?: string,
    inStatus?: QuoteStatusEnum[],
    inRequestStatus?: QuoteRequestStatusEnum[],
    hasRating?: boolean,
    frequentFilter?: string,
    origin?: string,
    destination?: string,
    commodity?: string,
    airline?: string,
    fromDate?: string,
    toDate?: string,
    mode?: ObjectReturnModeEnum,
    forwarder?: string,
    inBookingType?: string[],
    search?: string,
  ): Promise<IPaginationResults<IQuote>> {
    const extraOptions = {
      queryStringParameters: {
        page,
        size,
        sort,
      } as any,
    };
    if (type) {
      extraOptions.queryStringParameters.type = type;
    }
    if (origin) {
      extraOptions.queryStringParameters.origin = origin;
    }
    if (destination) {
      extraOptions.queryStringParameters.destination = destination;
    }
    if (commodity) {
      extraOptions.queryStringParameters.commodity = commodity;
    }
    if (forwarder) {
      extraOptions.queryStringParameters.forwarder = forwarder;
    }
    if (airline) {
      extraOptions.queryStringParameters.airline = airline;
    }
    if (inStatus) {
      extraOptions.queryStringParameters.inStatus = inStatus.join(',');
    }
    if (frequentFilter) {
      extraOptions.queryStringParameters.frequentFilter = frequentFilter;
    }
    if (inRequestStatus) {
      extraOptions.queryStringParameters.inRequestStatus =
        inRequestStatus.join(',');
    }
    if (fromDate) {
      extraOptions.queryStringParameters.fromDate = fromDate;
    }
    if (toDate) {
      extraOptions.queryStringParameters.toDate = toDate;
    }
    if (hasRating !== undefined && hasRating !== null) {
      extraOptions.queryStringParameters.hasRating = hasRating;
    }
    if (mode) {
      extraOptions.queryStringParameters.mode = mode;
    }
    if (inBookingType) {
      extraOptions.queryStringParameters.inBookingType = inBookingType;
    }
    if (search) {
      extraOptions.queryStringParameters.search = search;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Quote.Quotes,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }

  async subscribeTrackTrace(quoteId: number): Promise<void> {
    const url = API_CORE.URLs.Quote.TrackById.replace(
      '{quoteId}',
      quoteId.toString(),
    );
    return API.post(API_CORE.name, url, {});
  }

  async unSubscribeTrackTrace(quoteId: number): Promise<void> {
    const url = API_CORE.URLs.Quote.TrackById.replace(
      '{quoteId}',
      quoteId.toString(),
    );
    return API.del(API_CORE.name, url, {});
  }

  async addEmailTrackTrace(quoteId: number, email: string): Promise<void> {
    const extraOptions = {
        queryStringParameters: {
          email,
        },
      },
      url = API_CORE.URLs.Quote.TrackById.replace(
        '{quoteId}',
        quoteId.toString(),
      );
    return API.post(API_CORE.name, url, extraOptions);
  }

  async retrieveQuotesLight(): Promise<ILightQuote[]> {
    return API.get(API_CORE.name, API_CORE.URLs.Quote.QuotesLight, {}).catch(
      ErrorUtil.handleError,
    );
  }

  async retrieveQuoteById(quoteId: number, token?: string): Promise<IQuote> {
    const url = API_CORE.URLs.Quote.QuoteById.replace(
        '{quoteId}',
        quoteId.toString(),
      ),
      extraOptions = {
        queryStringParameters: {} as any,
      };
    if (token) {
      extraOptions.queryStringParameters.token = token;
    }
    return API.get(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async updateQuote(quote: IQuote, token?: string): Promise<IQuoteWithErrors> {
    const url = API_CORE.URLs.Quote.QuoteById.replace(
        '{quoteId}',
        quote?.quoteId?.toString(),
      ),
      extraOptions = {
        body: quote,
        queryStringParameters: {} as any,
      };
    if (token) {
      extraOptions.queryStringParameters.token = token;
    }
    return API.put(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async exportPDFForShipmentSummary(quote: IQuote): Promise<IDocumentExport> {
    return API.post(API_CORE.name, API_CORE.URLs.Quote.ExportPdf, {
      body: quote,
    });
  }

  async generateUploadUrlForAttachment(
    attachment: IQuoteAttachment,
  ): Promise<IPresignedQuoteAttachment> {
    return API.put(API_CORE.name, API_CORE.URLs.Quote.Attachment, {
      body: attachment,
    });
  }

  async generateUploadUrlForQuoteAttachment(
    quoteId: number,
    attachment: IQuoteAttachment,
  ): Promise<IPresignedQuoteAttachment> {
    const url = API_CORE.URLs.Quote.QuoteAttachmentById.replace(
      '{quoteId}',
      quoteId.toString(),
    );
    return API.put(API_CORE.name, url, {
      body: attachment,
    });
  }

  async generateDownloadUrlForAttachment(
    quoteAttachmentId: number,
    token?: string,
  ): Promise<IPresignedURL> {
    const url = API_CORE.URLs.Quote.AttachmentByAttachmentId.replace(
      '{quoteAttachmentId}',
      quoteAttachmentId.toString(),
    );
    let extraOptions = {};
    if (token) {
      extraOptions = {
        queryStringParameters: { token },
      };
    }
    return API.get(API_CORE.name, url, extraOptions);
  }

  async generateDownloadUrlForQuoteAttachment(
    quoteId: number,
    quoteAttachmentId: number,
    token?: string,
  ): Promise<IPresignedURL> {
    const url = API_CORE.URLs.Quote.QuoteAttachmentByAttachmentId.replace(
      '{quoteId}',
      quoteId.toString(),
    ).replace('{quoteAttachmentId}', quoteAttachmentId.toString());
    let extraOptions = {};
    if (token) {
      extraOptions = {
        queryStringParameters: { token },
      };
    }
    return API.get(API_CORE.name, url, extraOptions);
  }

  async generateDownloadUrlForQuoteGhaAttachment(
    quoteGhaAttachmentId: number,
    token?: string,
  ): Promise<IPresignedURL> {
    const url = API_CORE.URLs.Quote.GhaAttachmentByAttachmentId.replace(
      '{quoteGhaAttachmentId}',
      quoteGhaAttachmentId.toString(),
    );
    let extraOptions = {};
    if (token) {
      extraOptions = {
        queryStringParameters: { token },
      };
    }
    return API.get(API_CORE.name, url, extraOptions);
  }

  async markAttachmentUploadSuccessful(
    quoteAttachmentId: number,
  ): Promise<void> {
    const url = API_CORE.URLs.Quote.AttachmentByAttachmentId.replace(
        '{quoteAttachmentId}',
        quoteAttachmentId.toString(),
      ),
      extraOptions = {
        body: {
          uploadSuccessful: true,
        },
      };
    return API.patch(API_CORE.name, url, extraOptions);
  }

  async markQuoteAttachmentUploadSuccessful(
    quoteId: number,
    quoteAttachmentId: number,
  ): Promise<void> {
    const url = API_CORE.URLs.Quote.QuoteAttachmentByAttachmentId.replace(
        '{quoteId}',
        quoteId.toString(),
      ).replace('{quoteAttachmentId}', quoteAttachmentId.toString()),
      extraOptions = {
        body: {
          uploadSuccessful: true,
        },
      };
    return API.patch(API_CORE.name, url, extraOptions);
  }

  async deleteAttachment(quoteAttachmentId: number): Promise<any> {
    const url = API_CORE.URLs.Quote.AttachmentByAttachmentId.replace(
      '{quoteAttachmentId}',
      quoteAttachmentId.toString(),
    );
    return API.del(API_CORE.name, url, {});
  }

  async deleteQuoteAttachment(
    quoteId: number,
    quoteAttachmentId: number,
  ): Promise<any> {
    const url = API_CORE.URLs.Quote.QuoteAttachmentByAttachmentId.replace(
      '{quoteId}',
      quoteId.toString(),
    ).replace('{quoteAttachmentId}', quoteAttachmentId.toString());
    return API.del(API_CORE.name, url, {});
  }

  async retrieveAllMessages(
    quoteId: number,
    token?: string,
  ): Promise<IMessage[]> {
    const url = API_CORE.URLs.Quote.Messages.replace(
      '{quoteId}',
      quoteId.toString(),
    );
    let extraOptions = {};
    if (token) {
      extraOptions = {
        queryStringParameters: { token },
      };
    }
    return API.get(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async getFwbDetails(
    quoteId: number,
    token?: string,
  ): Promise<IEawbDetails[]> {
    const url = API_CORE.URLs.Quote.Fwb.replace(
        '{quoteId}',
        quoteId.toString(),
      ),
      extraOptions = {
        queryStringParameters: {} as any,
      };
    if (token) {
      extraOptions.queryStringParameters.token = token;
    }
    return API.get(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async getFhlDetails(
    quoteId: number,
    token?: string,
  ): Promise<IEawbDetails[]> {
    const url = API_CORE.URLs.Quote.Fhl.replace(
        '{quoteId}',
        quoteId.toString(),
      ),
      extraOptions = {
        queryStringParameters: {} as any,
      };
    if (token) {
      extraOptions.queryStringParameters.token = token;
    }
    return API.get(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async getSubscribedEmails(quoteId: number): Promise<string[]> {
    const url = API_CORE.URLs.Quote.subscribedEmails.replace(
      '{quoteId}',
      quoteId.toString(),
    );
    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.handleError);
  }

  async createFwb(
    quoteId: number,
    quoteEawbList: IEawbDetails[],
    sendFwb = false,
  ): Promise<EawbDetailsWithError> {
    const url = API_CORE.URLs.Quote.Fwb.replace(
        '{quoteId}',
        quoteId.toString(),
      ),
      body = quoteEawbList;
    return API.post(API_CORE.name, url, {
      body,
      queryStringParameters: {
        sendFwb,
      },
    }).catch(ErrorUtil.handleError);
  }

  async eawbExportPdf(printEawbInformation: any): Promise<IDocumentExport> {
    const body = printEawbInformation;
    return API.post(API_CORE.name, API_CORE.URLs.Quote.eawbExportPdf, {
      body,
    }).catch(ErrorUtil.handleError);
  }

  async createFhl(
    quoteId: number,
    quoteFhl: IFhlDetails,
    sendFhl: boolean,
  ): Promise<EawbDetailsWithError> {
    const url = API_CORE.URLs.Quote.Fhl.replace(
        '{quoteId}',
        quoteId.toString(),
      ),
      body = quoteFhl;
    return API.post(API_CORE.name, url, {
      body,
      queryStringParameters: {
        sendFhl,
      },
    }).catch(ErrorUtil.handleError);
  }

  async deleteFhl(quoteId: number, quoteFhl: IFhlDetails): Promise<void> {
    const url = API_CORE.URLs.Quote.Fhl.replace(
        '{quoteId}',
        quoteId.toString(),
      ),
      body = quoteFhl;
    return API.del(API_CORE.name, url, {
      body,
    }).catch(ErrorUtil.handleError);
  }

  async resendEmail(quoteId: number): Promise<any> {
    const extraOptions = {
        body: {},
        queryStringParameters: {} as any,
      },
      url = API_CORE.URLs.Quote.ResendEmail.replace(
        '{quoteId}',
        quoteId.toString(),
      );
    return API.post(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async createMessage(
    quoteId: number,
    message: IMessage,
    token?: string,
  ): Promise<IMessage> {
    const url = API_CORE.URLs.Quote.Messages.replace(
        '{quoteId}',
        quoteId.toString(),
      ),
      extraOptions = {
        body: message,
        queryStringParameters: {} as any,
      };
    if (token) {
      extraOptions.queryStringParameters.token = token;
    }
    return API.post(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async getQuoteMetadata(
    type: MetadataTypeEnum,
    fromDate?: Date,
    toDate?: Date,
    origin?: string[],
    destination?: string[],
    airline?: string[],
    commodity?: string[],
    forwarder?: string[],
    bookingType?: string[],
    search?: string,
  ): Promise<IQuoteMetadata> {
    const extraOptions = {
      queryStringParameters: {
        type,
      } as any,
    };
    if (fromDate) {
      extraOptions.queryStringParameters.fromDate = fromDate;
    }
    if (toDate) {
      extraOptions.queryStringParameters.toDate = toDate;
    }
    if (origin) {
      extraOptions.queryStringParameters.origin = origin.join(',');
    }
    if (forwarder) {
      extraOptions.queryStringParameters.forwarder = forwarder.join(',');
    }
    if (destination) {
      extraOptions.queryStringParameters.destination = destination.join(',');
    }
    if (airline && airline.length > 0) {
      extraOptions.queryStringParameters.airline = airline.join(',');
    }
    if (commodity) {
      extraOptions.queryStringParameters.commodity = commodity.join(',');
    }
    if (bookingType && bookingType.length > 0) {
      extraOptions.queryStringParameters.inBookingType = bookingType.join(',');
    }
    if (search) {
      extraOptions.queryStringParameters.search = search;
    }
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Quote.Metadata,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }
}
