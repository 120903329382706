import moment from 'moment';
import { MeasurementSystemEnum } from '@cai-services';
import { TimeFormatPipe } from '../core/_base/layout/pipes/time-format.pipe';
import {
  DEFAULT_DATETIME_FORMAT,
  NARROW_AIRCRAFT_MAX_HEIGHT,
  NARROW_AIRCRAFT_MAX_LENGTH,
  NARROW_AIRCRAFT_MAX_WEIGHT,
  NARROW_AIRCRAFT_MAX_WIDTH,
  WIDEBODY_AIRCRAFT_MAX_HEIGHT,
  WIDEBODY_AIRCRAFT_MAX_LENGTH,
  WIDEBODY_AIRCRAFT_MAX_WEIGHT,
  WIDEBODY_AIRCRAFT_MAX_WIDTH,
} from '../core/_constants/constants';
import { AirportLight } from '../core/_models/airport-light.model';
import {
  CustomFlightDetails,
  FlightInput,
  Leg,
} from '../core/_models/flight-input.model';
import { FlightRate } from '../core/_models/flight-rate.model';
import { getStringDate } from '../helpers/date-helpers';
import { SPECIAL_AIRLINE_CASES } from '../helpers/search_helpers';
import { AircraftTypes } from '../../lib/core/_enums/aircraft-enum';
import { MeasurementUtil } from '../../lib/core/_base/crud/utils/measurement.util';
import { SearchTemplate } from '../core';

export class SearchResultUtil {
  private static readonly timeFormatPipe = new TimeFormatPipe();

  private static formatArrivalTime(arrivalTime: string): Date {
    const stringDate = getStringDate(arrivalTime);
    const stringTime = SearchResultUtil.timeFormatPipe.transform(arrivalTime);
    return moment(
      `${stringDate} ${stringTime}`,
      DEFAULT_DATETIME_FORMAT,
    ).toDate();
  }

  private static cheaperComparator(flightA: FlightInput, flightB: FlightInput) {
    const rateDifference =
      flightA.rates[0].allInRate - flightB.rates[0].allInRate;
    return rateDifference !== 0
      ? rateDifference
      : SearchResultUtil.greenerComparator(flightA, flightB);
  }

  private static greenerComparator(flightA: FlightInput, flightB: FlightInput) {
    const co2Difference =
      +SearchResultUtil.getCO2(flightA.legs) -
      +SearchResultUtil.getCO2(flightB.legs);
    return co2Difference !== 0
      ? co2Difference
      : SearchResultUtil.earlierArrivalComparator(flightA, flightB);
  }

  private static earlierArrivalComparator(
    flightA: FlightInput,
    flightB: FlightInput,
  ) {
    return (
      +SearchResultUtil.formatArrivalTime(flightA.arrivalTime) -
      +SearchResultUtil.formatArrivalTime(flightB.arrivalTime)
    );
  }

  static getSortedFlightsByEarliestArrival(flights: FlightInput[]) {
    return flights.slice().sort(this.earlierArrivalComparator);
  }

  static getSortedFlightsByExpressRate(flights: FlightInput[]) {
    const flightsWithRate = flights.filter(
      (flight) => flight.rates?.length > 0 && flight.features.bookable,
    );
    const flightsWithExpressRate = flightsWithRate.filter((flight) =>
      flight.rates.some((rate) => rate.type === 'express'),
    );
    flightsWithExpressRate.sort(SearchResultUtil.cheaperComparator);

    return [...flightsWithExpressRate];
  }

  static getSortedFlightsByCheapest(flights: FlightInput[]) {
    const flightsWithRate = [];
    const flightsWithoutRate = [];

    flights.forEach((flight) => {
      (flight.rates?.length ? flightsWithRate : flightsWithoutRate).push(
        flight,
      );
    });

    flightsWithRate.sort(SearchResultUtil.cheaperComparator);
    return [...flightsWithRate, ...flightsWithoutRate];
  }

  static getSortedFlightsByGreenest(flights: FlightInput[]): FlightInput[] {
    return flights.slice().sort(SearchResultUtil.greenerComparator);
  }

  static sortAirlineFlightsByBest(flightInputs: FlightInput[]): FlightInput[] {
    const flightsWithRate = flightInputs.filter(
      (flight) => flight.rates && flight.rates.length,
    );
    const flightsAvailable = flightsWithRate.filter(
      (flight) => flight.available,
    );
    const flightsUnavailable = flightsWithRate.filter(
      (flight) => !flight.available,
    );

    const sortedByRate = SearchResultUtil.groupFlightsByRate(
      flightsAvailable,
    ).flatMap((group) => SearchResultUtil.sortByArrivalDate(group.flights));

    return [
      ...sortedByRate,
      ...SearchResultUtil.sortByArrivalDate(flightsUnavailable),
      ...SearchResultUtil.sortByArrivalDate(
        flightInputs.filter((flight) => !flight.rates?.length),
      ),
    ];
  }

  static groupLowerShipmentCost(flights: FlightInput[]): FlightInput[] {
    const flightsWithRate = flights.filter((flight) => flight?.rates?.length);
    const flightsAvailable = flightsWithRate.filter(
      (flight) => flight.available,
    );
    const flightsUnavailable = flightsWithRate.filter(
      (flight) => !flight.available,
    );

    const sortByRate = (rateFlights: FlightInput[]) =>
      SearchResultUtil.groupFlightsByRate(rateFlights).flatMap((group) =>
        group.flights.sort(SearchResultUtil.earlierArrivalComparator),
      );

    return [
      ...sortByRate(flightsAvailable),
      ...sortByRate(flightsUnavailable),
      ...flights
        .filter((flight) => !flight.rates?.length)
        .sort(SearchResultUtil.earlierArrivalComparator),
    ];
  }

  static groupDirectFlights(flights: FlightInput[]): FlightInput[] {
    return [
      ...SearchResultUtil.groupLowerShipmentCost(
        flights.filter((flight) => flight.legs.length === 1),
      ),
      ...SearchResultUtil.groupLowerShipmentCost(
        flights.filter((flight) => flight.legs.length > 1),
      ),
    ];
  }

  static sortByArrivalDate(flights: FlightInput[]): FlightInput[] {
    return SearchResultUtil.groupFlightsByArrivalDate(flights).flatMap(
      (group) => SearchResultUtil.getSortedFlightsByGreenest(group.flights),
    );
  }

  static groupFlightsByRate(
    flightInputs: FlightInput[],
  ): { rate: number; flights: FlightInput[] }[] {
    const groupedByRate: { rate: number; flights: FlightInput[] }[] = [];

    flightInputs.forEach((flight) => {
      const allInRate = flight.rates?.[0]?.allInRate ?? 0;
      const existingGroup = groupedByRate.find(
        (group) => group.rate === allInRate,
      );

      existingGroup
        ? existingGroup.flights.push(flight)
        : groupedByRate.push({ rate: allInRate, flights: [flight] });
    });

    return groupedByRate.sort((a, b) => a.rate - b.rate);
  }

  static groupFlightsByArrivalDate(
    flightInputs: FlightInput[],
  ): { arrivalDate: number; flights: FlightInput[] }[] {
    const groupedByArrival: { arrivalDate: number; flights: FlightInput[] }[] =
      [];

    flightInputs.forEach((flight) => {
      const arrivalTime = new Date(
        flight.legs[flight.legs.length - 1].arrivalTime,
      ).getTime();
      const existingGroup = groupedByArrival.find(
        (group) => group.arrivalDate === arrivalTime,
      );

      existingGroup
        ? existingGroup.flights.push(flight)
        : groupedByArrival.push({
            arrivalDate: arrivalTime,
            flights: [flight],
          });
    });

    return groupedByArrival.sort((a, b) => a.arrivalDate - b.arrivalDate);
  }

  static getCO2(legs: Leg[]): string {
    return (
      legs
        ?.filter((path) => path.co2)
        .reduce((sum, path) => sum + Math.ceil(path.co2.value), 0)
        .toString() ?? '0'
    );
  }

  static getFlightDaysToView(
    shipmentDate: Date,
    flights: FlightInput[],
    searchDate: Date,
    nav: 'prev' | 'next',
  ): number {
    const minFlightDate = moment(shipmentDate).startOf('day');
    const maxFlightDate = moment(this.getMaxArrivalDate(flights));
    const tempSearchDate = moment(
      nav === 'next' ? this.addDaysToDate(searchDate, 4) : searchDate,
    ).startOf('day');
    const newSearchDate = moment(
      this.addDaysToDate(tempSearchDate.toDate(), nav === 'next' ? 3 : -3),
    ).startOf('day');

    if (nav === 'prev' && newSearchDate < minFlightDate) {
      return moment(tempSearchDate).diff(minFlightDate, 'days');
    }
    if (nav === 'next' && newSearchDate > maxFlightDate) {
      return moment(maxFlightDate).diff(tempSearchDate, 'days');
    }

    return 3;
  }

  static addDaysToDate(date: Date, days: number): Date {
    return moment(date).add(days, 'days').toDate();
  }

  static getMaxArrivalDate(flights: FlightInput[]): Date {
    return moment
      .max(flights.map((flight) => moment(flight.arrivalTime.split('T')[0])))
      .startOf('day')
      .toDate();
  }

  static getFormattedRateName(selectedRate): string {
    let rateName = '';
    if (selectedRate) {
      if (!!selectedRate.rateId) {
        if (!!selectedRate.airlineProdCode) {
          rateName = selectedRate.airlineProdCode + '_';
        }
        if (!!selectedRate.type && selectedRate.type === 'Express') {
          rateName = rateName + selectedRate.type + '_' + selectedRate.name;
        } else {
          rateName = rateName + selectedRate.type;
        }
        rateName = rateName + ' Rate';
        return rateName;
      }
      if (selectedRate.formattedName) {
        rateName = selectedRate.formattedName;
      }

      if (selectedRate.name) {
        if (rateName.length) {
          rateName += ' ';
        }
        rateName += selectedRate.name;
      }
    }
    return rateName;
  }

  static computeFlights(
    data: FlightInput[],
    flightDays: Date[],
    airlineCompanies: any[],
  ): FlightInput[] {
    const fromDate = moment(new Date(flightDays[0])).startOf('day').toDate();
    const toDate = moment(new Date(flightDays[flightDays.length - 1]))
      .endOf('day')
      .toDate();

    return data
      .filter((item) => {
        const departureDate = new Date(
          new Date(item.departureTime).setHours(0, 0, 0),
        ).getTime();
        return departureDate >= +fromDate && departureDate <= +toDate;
      })
      .map((item) => {
        let airlineCode = item.airlineCode;
        const specialCase = SPECIAL_AIRLINE_CASES.find(
          (airline) => airline.id === airlineCode,
        );
        if (specialCase) airlineCode = specialCase.group;
        item.airlineCompany = this.getAirlineByCode(
          airlineCode,
          airlineCompanies,
        );
        return item;
      })
      .filter(
        (item) =>
          item.airlineCompany ||
          console.warn(`Unknown airline ${item.airlineCode}`),
      );
  }

  static getAirlineByCode(code: string, airlineCompanies: any[]) {
    return airlineCompanies.find((ac) => ac.airlineCompanyCode === code);
  }

  static getRateName(rate: FlightRate): string {
    const rateNames = {
      market: $localize`:@@global.market-rate:Market Rate`,
      live: $localize`:@@global.live-rate:Live Rate`,
      fresh: $localize`:@@global.fresh-rate:Fresh Rate`,
      express: $localize`:@@global.express-rate:Express Rate`,
      contract: $localize`:@@global.contract-rate:Contract Rate`,
      promo: $localize`:@@global.promo-rate:Promo Rate`,
    };

    return rateNames[rate.type] || '';
  }

  static prepareAirportName(airport: AirportLight): string {
    if (!airport) return '';
    const airportDetails = [];
    if (airport.airportName === 'International')
      airportDetails.push(airport.cityName);
    airportDetails.push(airport.airportName, `(${airport.airportCode})`);
    return airportDetails.join(' ');
  }

  static isQuotable(
    flightDetail: CustomFlightDetails,
    allowedCraftTypes: AircraftTypes[],
  ): boolean {
    return (
      (flightDetail.features?.mailQuotable == null ||
        flightDetail.features?.mailQuotable ||
        flightDetail.features?.equote) &&
      flightDetail.legs.every((leg) =>
        allowedCraftTypes?.includes(
          leg.bodyType?.toLowerCase() as AircraftTypes,
        ),
      )
    );
  }

  static getAllowedAircraftTypes(
    searchTemplate: SearchTemplate,
  ): AircraftTypes[] {
    if (this.isFreighterOnly(searchTemplate)) {
      return [AircraftTypes.FREIGHTER, AircraftTypes.SURFACE];
    } else if (this.isFreighterAndWidebody(searchTemplate)) {
      return [
        AircraftTypes.FREIGHTER,
        AircraftTypes.WIDEBODY,
        AircraftTypes.SURFACE,
      ];
    }
    return [
      AircraftTypes.FREIGHTER,
      AircraftTypes.WIDEBODY,
      AircraftTypes.NARROWBODY,
      AircraftTypes.SURFACE,
    ];
  }

  static isFreighterOnly(searchTemplate: SearchTemplate): boolean {
    return searchTemplate?.searchItemTemplates?.some(
      (quoteItem) =>
        quoteItem.length >
          this.convertMeasure(WIDEBODY_AIRCRAFT_MAX_LENGTH, searchTemplate) ||
        quoteItem.width >
          this.convertMeasure(WIDEBODY_AIRCRAFT_MAX_WIDTH, searchTemplate) ||
        quoteItem.height >
          this.convertMeasure(WIDEBODY_AIRCRAFT_MAX_HEIGHT, searchTemplate) ||
        quoteItem.weight > WIDEBODY_AIRCRAFT_MAX_WEIGHT,
    );
  }

  static isFreighterAndWidebody(searchTemplate: SearchTemplate): boolean {
    return searchTemplate?.searchItemTemplates?.some(
      (quoteItem) =>
        quoteItem.length >
          this.convertMeasure(NARROW_AIRCRAFT_MAX_LENGTH, searchTemplate) ||
        quoteItem.width >
          this.convertMeasure(NARROW_AIRCRAFT_MAX_WIDTH, searchTemplate) ||
        quoteItem.height >
          this.convertMeasure(NARROW_AIRCRAFT_MAX_HEIGHT, searchTemplate) ||
        quoteItem.weight > NARROW_AIRCRAFT_MAX_WEIGHT,
    );
  }

  static convertMeasure(value: number, searchTemplate: SearchTemplate): number {
    return MeasurementUtil.convertMeasure(
      value,
      MeasurementSystemEnum.METRIC,
      searchTemplate.measurementSystem,
    );
  }
}
