import { FormGroup } from "@angular/forms";
import {
  ICommodity,
  IDangerousGood,
  IRequirementSearchTemplate,
  IScr, ISpecialHandlingCode,
  LoadTypeEnum,
  MeasurementSystemEnum,
  PackingTypeEnum, RateTypeEnum, RateWeightTypeEnum,
  RequirementCodeEnum,
  WeightTypeEnum
} from "@cai-services";
import { LoadTypeUnit } from "../components/load-type/model/load-type-unit.model";
import { CaiBUPUtil } from "../components/load-type/utils/bup.util";
import { CaiDimensionUtil } from "../components/load-type/utils/dimension.util";
import { MeasurementUtil } from "../core/_base/crud/utils/measurement.util";
import { BUP } from "../core/_models/bup.model";
import { DangerousGood } from "../core/_models/dangerous-good.model";
import { Dimension } from "../core/_models/dimension.model";
import { MeasurementUnit } from "../core/_models/measurement-system.model";
import { QuoteItem } from "../core/_models/quote-item.model";
import { SpecialHandlingCode } from "../core/_models/special-handling-code.model";
import { TypeOfProduct } from "../core/_models/type-of-product.model";
import { Quote, AirportLight, FlightInput, QuoteRateDetail, SearchTemplate, Surcharges } from "../core";
import { DangerousGoodUtil } from "./dangerous-goods.util";
import { ExpressAnswer } from "../constant";

export class SearchUtil {
  static MAX_COMMODITY_LEVEL_TO_USE_SHC = 3;
  static SPECIAL_REQUIREMENTS_TO_USE_SHC = [
    RequirementCodeEnum.DIPLOMATIC,
    RequirementCodeEnum.EXPRESS,
    RequirementCodeEnum.SCREENED,
    RequirementCodeEnum.KNOWN_SHIPPER,
    RequirementCodeEnum.CARGO_AIRCRAFT_ONLY,
    RequirementCodeEnum.LITHIUM_BATTERY_DETAIL,
  ];

  static prepareTypeOfProduct (
    typeOfProducts: TypeOfProduct[],
    goodsType: ICommodity,
    reqFormGroup: FormGroup,
  ): TypeOfProduct {
    let goodsTypeKeyword = goodsType?.name.split(" ")[0];

    //Special handling for Dangerous Goods to map
    //to the correct TypeOfProduct for search results
    if (goodsType?.name === "Dangerous Goods") {
      const airCraftOnlyAnswer = reqFormGroup.get(
        RequirementCodeEnum.CARGO_AIRCRAFT_ONLY,
      )?.value,
       airCraftOnlyAnswers = goodsType.requirements?.find((req) => req.code === RequirementCodeEnum.CARGO_AIRCRAFT_ONLY).answers,
       shc = airCraftOnlyAnswers?.find(
        (answer) => answer.code === airCraftOnlyAnswer,
      )?.specialHandlingCodes?.[0];
      if (airCraftOnlyAnswer) {
        goodsTypeKeyword = shc.shcCode;
      }
    }

    return typeOfProducts.find((type) =>
      type.productDescription.includes(
        goodsTypeKeyword.substring(0, goodsTypeKeyword.length - 1),
      ),
    );
  }

  static getDimensionFromQuoteItemList (
    quoteItems: QuoteItem[],
    measurementSystem: MeasurementSystemEnum,
    loadTypeMode: LoadTypeEnum,
  ) {
    return quoteItems.map((item) => ({
      "width": +item.dimensionWidth || 0,
      "height": +item.dimensionHeight || 0,
      "length": +item.dimensionLength || 0,
      "pieces": +item.numOfItems,
      "tiltable":
        item.packing === PackingTypeEnum.TURNABLE ||
        item.packing === PackingTypeEnum.STACKABLE_TURNABLE,
      "stackable":
        item.packing === PackingTypeEnum.STACKABLE ||
        item.packing === PackingTypeEnum.STACKABLE_TURNABLE,
      "weight": +item.weight,
      "weightType": item.weightType,
      "loadType": item.loadType,
      "uldType": item.uldType,
      "volume": +this.calculateVolumePerItem(
        item,
        measurementSystem,
        loadTypeMode,
      ),
    }));
  }

  static calculateVolumePerItem (
    item,
    measurementSystem: MeasurementSystemEnum,
    loadTypeMode: LoadTypeEnum,
  ) {
    switch (loadTypeMode) {
      case LoadTypeEnum.TOTAL:
        return MeasurementUtil.calculateVolume(
          item.width,
          item.length,
          item.height,
          item.numOfItems,
          measurementSystem,
        );
      case LoadTypeEnum.DIMENSIONS:
        return MeasurementUtil.calculateVolume(
          item.dimensionWidth,
          item.dimensionLength,
          item.dimensionHeight,
          item.numOfItems,
          measurementSystem,
        );
      case LoadTypeEnum.BUP:
        return +item.volume * +item.numOfItems;
      default:
        return 0;
    }
  }

  static prepareQuoteItems (
    loadType: LoadTypeEnum,
    formGroup: FormGroup,
    defaultMeasurementSystem: MeasurementSystemEnum,
    currentMeasurementSystem: MeasurementSystemEnum,
    typeOfProduct: TypeOfProduct,
    dimensions: Dimension[],
    bups: BUP[],
    weightUnitDetail: MeasurementUnit,
    dimensionUnitDetails: MeasurementUnit,
    dimensionUnitState?: LoadTypeUnit,
    bupUnitState?: LoadTypeUnit,
  ): QuoteItem[] {
    switch (loadType) {
      case LoadTypeEnum.TOTAL:
        return this.createFakeQuoteItem(
          formGroup,
          defaultMeasurementSystem,
          currentMeasurementSystem,
          typeOfProduct,
        );
      case LoadTypeEnum.DIMENSIONS:
        return dimensions
          .filter((item) =>
            CaiDimensionUtil.isDimensionValid(
              item,
              weightUnitDetail,
              dimensionUnitDetails,
            ),
          )
          .map((item) => ({
              "quoteItemId": 0,
              "numOfItems": item.numOfItems,
              "weight": this.convertWeight(
                item.weight,
                dimensionUnitState
                  ? dimensionUnitState?.weightUnitCode === "kg"
                    ? MeasurementSystemEnum.METRIC
                    : MeasurementSystemEnum.IMPERIAL
                  : currentMeasurementSystem,
              ),
              "dimensionLength": this.convertDimension(
                item.dimensionLength,
                defaultMeasurementSystem,
                dimensionUnitState
                  ? dimensionUnitState?.dimensionUnitCode === "cm"
                    ? MeasurementSystemEnum.METRIC
                    : MeasurementSystemEnum.IMPERIAL
                  : currentMeasurementSystem,
              ),
              "dimensionWidth": this.convertDimension(
                item.dimensionWidth,
                defaultMeasurementSystem,
                dimensionUnitState
                  ? dimensionUnitState?.dimensionUnitCode === "cm"
                    ? MeasurementSystemEnum.METRIC
                    : MeasurementSystemEnum.IMPERIAL
                  : currentMeasurementSystem,
              ),
              "dimensionHeight": this.convertDimension(
                item.dimensionHeight,
                defaultMeasurementSystem,
                dimensionUnitState
                  ? dimensionUnitState?.dimensionUnitCode === "cm"
                    ? MeasurementSystemEnum.METRIC
                    : MeasurementSystemEnum.IMPERIAL
                  : currentMeasurementSystem,
              ),
              "packing": item.packing,
              "weightType": item.weightType,
              typeOfProduct,
              "loadType": LoadTypeEnum.DIMENSIONS,
            } as QuoteItem));
      case LoadTypeEnum.BUP:
        return bups
          .filter((item) => CaiBUPUtil.isBUPValid(item, weightUnitDetail))
          .map((item) => ({
              "quoteItemId": 0,
              "numOfItems": item.quantity,
              "weight": this.convertWeight(
                item.weight,
                bupUnitState
                  ? bupUnitState?.weightUnitCode === "kg"
                    ? MeasurementSystemEnum.METRIC
                    : MeasurementSystemEnum.IMPERIAL
                  : currentMeasurementSystem,
              ),
              "dimensionLength": 0,
              "dimensionWidth": 0,
              "dimensionHeight": 0,
              "packing": PackingTypeEnum.NONE,
              "uldType": item.uldType,
              "volume": item.volume,
              "weightType": item.weightType,
              typeOfProduct,
              "loadType": LoadTypeEnum.BUP,
            } as QuoteItem));
      default:
        return [];
    }
  }

  static createFakeQuoteItem (
    formGroup: FormGroup,
    defaultMeasurementSystem: MeasurementSystemEnum,
    currentMeasurementSystem: MeasurementSystemEnum,
    typeOfProduct: TypeOfProduct,
    useNewCalculation = false,
  ): QuoteItem[] {
    if (useNewCalculation) {
      return this.createNewFakeQuoteItem(
        formGroup,
        currentMeasurementSystem,
        typeOfProduct,
      );
    }
    const weight = +formGroup.get("weight").value,
     volume = +formGroup.get("volume").value,
     pieces = +formGroup.get("pieces").value;
    let fakeHeight = -100,
     fakeLength = -100,
     fakeWidth = (volume * -100) / pieces;
    if (defaultMeasurementSystem === MeasurementSystemEnum.IMPERIAL) {
      fakeHeight = -12;
      fakeLength = -12;
      fakeWidth = (volume * -12) / pieces;
    }

    return [
      {
        "numOfItems": pieces,
        "weight": this.convertWeight(weight, currentMeasurementSystem),
        "dimensionLength": fakeLength,
        "dimensionWidth": fakeWidth,
        "dimensionHeight": fakeHeight,
        "packing": PackingTypeEnum.STACKABLE,
        "weightType": WeightTypeEnum.TOTAL,
        typeOfProduct,
        "loadType": LoadTypeEnum.TOTAL,
      } as QuoteItem,
    ];
  }

  static createNewFakeQuoteItem (
    formGroup: FormGroup,
    currentMeasurementSystem: MeasurementSystemEnum,
    typeOfProduct: TypeOfProduct,
  ): QuoteItem[] {
    const weight = this.convertWeight(
      +formGroup.get("weight").value,
      currentMeasurementSystem,
    ),
     volume = this.convertVolume(
      +formGroup.get("volume").value,
      currentMeasurementSystem,
    ),
     pieces = +formGroup.get("pieces").value;
    if (volume <= 1) {
      const fakeWidth = SearchUtil.roundTo((volume * 100) / pieces, 0);
      return [
        this.createFakeDimension(
          fakeWidth,
          100,
          100,
          pieces,
          weight,
          volume,
          WeightTypeEnum.TOTAL,
          LoadTypeEnum.DIMENSIONS,
          PackingTypeEnum.STACKABLE,
          typeOfProduct,
        ),
      ];
    } else {
      const fakePieces = (volume / (50 * 50 * 50)) * 1000000;
      if (fakePieces % 1 === 0) {
        return [
          this.createFakeDimension(
            50,
            50,
            50,
            fakePieces,
            weight,
            volume,
            WeightTypeEnum.TOTAL,
            LoadTypeEnum.DIMENSIONS,
            PackingTypeEnum.STACKABLE,
            typeOfProduct,
          ),
        ];
      } else {
        const roundedFakePieces: number = Math.floor(fakePieces),
         remainingFakePieces: number = fakePieces - roundedFakePieces,
         remainingVolume: number = remainingFakePieces * 125000,
         fakeLength: number = this.roundTo(remainingVolume / 500, 0);
        return [
          this.createFakeDimension(
            50,
            50,
            50,
            roundedFakePieces,
            SearchUtil.roundTo(weight / (roundedFakePieces + 1), 4),
            volume,
            WeightTypeEnum.PER_ITEM,
            LoadTypeEnum.TOTAL,
            PackingTypeEnum.STACKABLE,
            typeOfProduct,
          ),
          this.createFakeDimension(
            50,
            10,
            fakeLength,
            1,
            SearchUtil.roundTo(weight / (roundedFakePieces + 1), 4),
            volume,
            WeightTypeEnum.PER_ITEM,
            LoadTypeEnum.DIMENSIONS,
            PackingTypeEnum.STACKABLE,
            typeOfProduct,
          ),
        ];
      }
    }
  }

  static createFakeDimension (
    width: number,
    height: number,
    length: number,
    pieces: number,
    weight: number,
    volume: number,
    weightType: WeightTypeEnum,
    loadType: LoadTypeEnum,
    packing: PackingTypeEnum,
    typeOfProduct: TypeOfProduct,
  ): QuoteItem {
    return {
      "dimensionWidth": width,
      "dimensionHeight": height,
      "dimensionLength": length,
      "numOfItems": pieces,
      weight,
      volume,
      weightType,
      loadType,
      packing,
      typeOfProduct,
    } as QuoteItem;
  }

  static convertDimension (
    value,
    defaultMeasurementSystem: MeasurementSystemEnum,
    currentMeasurementSystem: MeasurementSystemEnum,
  ) {
    return MeasurementUtil.convertMeasure(
      value,
      currentMeasurementSystem,
      defaultMeasurementSystem,
    );
  }

  static convertWeight (value, measurementSystem: MeasurementSystemEnum) {
    return MeasurementUtil.convertWeight(
      value,
      measurementSystem,
      MeasurementSystemEnum.METRIC,
    );
  }

  static convertVolume (value, measurementSystem: MeasurementSystemEnum) {
    return MeasurementUtil.convertVolume(
      value,
      measurementSystem,
      MeasurementSystemEnum.METRIC,
    );
  }

  static roundTo (nb: number, decimals: number): number {
    return Math.round(nb * Math.pow(10, decimals)) / Math.pow(10, decimals);
  }

  static prepareShcList (
    requirements: IRequirementSearchTemplate[],
    commodities: ICommodity[],
    additionalSHCs: SpecialHandlingCode[],
    type: "code" | "id",
  ): string[] {
    const shcList = [];
    requirements?.forEach((requirement) => {
      if (requirement.answer) {
        requirement.answer.specialHandlingCodes?.forEach((shc) => {
          if (type === "code") {
            shcList.push(shc.shcCode);
          } else if (type === "id") {
            shcList.push(shc.shcId?.toString());
          }
        });
      }
    });
    commodities.forEach((commodity) => {
      commodity.specialHandlingCodes.forEach((shc) => {
        if (type === "code") {
          shcList.push(shc.shcCode);
        } else if (type === "id") {
          shcList.push(shc.shcId?.toString());
        }
      });
    });
    additionalSHCs
      .filter((shc) => !!shc)
      .forEach((shc) => {
        if (type === "code") {
          shcList.push(shc.shcCode);
        } else if (type === "id") {
          shcList.push(shc.shcId?.toString());
        }
      });
    return shcList;
  }

  static prepareScrList (commodities: ICommodity[]): string[] {
    return commodities
      ?.map((com) => com.scrs)
      ?.reduce((array, scrs) => array.concat(scrs), [] as IScr[])
      ?.map((scr) => scr.code);
  }

  static prepareDangerousGoods (
    dangerousGoods: DangerousGood[],
    isCAO = false,
  ): IDangerousGood[] {
    return dangerousGoods
      .filter((good) => good.unid && good.searchResult)
      .filter((item) => DangerousGoodUtil.isDangerousGoodValid(item, isCAO))
      .map((good) => {
        const mapped = {
          "id": good.id,
          "unid": good.unid,
          "goodClass": good.goodClass,
          "packagingGroup": good.packagingGroup,
          "packagingInstruction": good.packagingInstruction,
          "totalNet": +good.totalNet,
          "uom": good.uom,
          "caoPackagingInstruction": good.caoPackagingInstruction,
          "caoTotalNet": +good.caoTotalNet,
          "caoUom": good.caoUom,
          "shc": good.shc,
        };
        if (DangerousGoodUtil.isNonCAOFieldsEnabled(good, isCAO, true)) {
          mapped.caoPackagingInstruction = null;
          mapped.caoTotalNet = null;
          mapped.caoUom = null;
        } else {
          mapped.packagingInstruction = null;
          mapped.totalNet = null;
          mapped.uom = null;
        }
        return mapped;
      });
  }

  static getQuoteItemsFromTemplate (
    searchTemplate: SearchTemplate,
    typesOfProducts: TypeOfProduct[],
  ): QuoteItem[] {
    return searchTemplate.searchItemTemplates.map((item) => {
      const quoteItem = {
        "weight": item.weight,
        "numOfItems": item.numOfItems,
        "typeOfProduct": typesOfProducts.find(
          (goodType) =>
            goodType?.productCode === searchTemplate.typeOfProduct?.productCode,
        ),
        "weightType": item.weightType,
        "loadType": item.itemType,
      } as QuoteItem;
      if (quoteItem.loadType === LoadTypeEnum.BUP) {
        quoteItem.volume = item.volume;
        quoteItem.uldType = item.uldType;
      } else {
        quoteItem.dimensionWidth = item.width;
        quoteItem.dimensionLength = item.length;
        quoteItem.dimensionHeight = item.height;
        quoteItem.packing = item.packingType;
      }
      return quoteItem;
    });
  }

  static getSpecialHandlingCodesFromSearch(
    searchTemplate: SearchTemplate,
    bookedFlight: any,
    allShcs: SpecialHandlingCode[],
    isBup: boolean,
  ): ISpecialHandlingCode[] {
    const bupSHCs: SpecialHandlingCode[] = [];
    const bupSHC = allShcs.find((shc) => shc.shcCode === 'BUP');
    if (bupSHC) {
      bupSHCs.push(bupSHC);
    }
    const uldSHC = allShcs.find((shc) => shc.shcCode === 'ULD');
    if (uldSHC) {
      bupSHCs.push(uldSHC);
    }

    let shcs: SpecialHandlingCode[] = [];
    if (bookedFlight.selectedRate.handlingCodes) {
      for (const handlingCode of bookedFlight.selectedRate.handlingCodes) {
        const shc = allShcs.find(code => code.shcCode === handlingCode);
        shcs.push(shc);
      }
    } else {
      shcs = SearchUtil.parseShcCodesFromSearchTemplate(
        searchTemplate,
        isBup,
        bupSHCs,
      );
      const express = searchTemplate.requirements
        .find(
          (requirement) =>
            requirement?.requirement?.code === RequirementCodeEnum.EXPRESS,
        )
        ?.requirement?.answers?.find(
          (answer) => answer.code === ExpressAnswer.YES,
        )?.specialHandlingCodes[0];
      if (bookedFlight.selectedRate.type.includes('express')) {
        if (!shcs.some((shc) => shc?.shcId === express?.shcId)) {
          shcs.push(express);
        }
      }
    }
    return shcs;
  }

  static parseShcCodesFromSearchTemplate (
    searchTemplate: SearchTemplate,
    isBup: boolean,
    bupSHCs: SpecialHandlingCode[],
  ): SpecialHandlingCode[] {
    const shcCodes: SpecialHandlingCode[] = [];
    if (isBup) {
      bupSHCs.forEach((shc) => {
        shcCodes.push(shc);
      });
    }
    if (searchTemplate.commodities) {
      searchTemplate.commodities.forEach((comm) => {
        comm.specialHandlingCodes.forEach((commodityShc) => {
          const existing = shcCodes.find(
            (shc) => shc?.shcId === commodityShc.shcId,
          );
          if (
            !existing &&
            comm.level <= SearchUtil.MAX_COMMODITY_LEVEL_TO_USE_SHC
          ) {
            shcCodes.push(commodityShc);
          }
        });
      });
    }
    if (searchTemplate.requirements) {
      SearchUtil.SPECIAL_REQUIREMENTS_TO_USE_SHC.forEach((requirementCode) => {
        const matchedRequirement = searchTemplate.requirements.find(
          (req) => req.answer && req.requirement.code === requirementCode,
        );
        if (matchedRequirement) {
          matchedRequirement.answer.specialHandlingCodes.forEach(
            (requirementShc) => {
              shcCodes.push(requirementShc);
            },
          );
        }
      });

      searchTemplate.requirements.forEach((req) => {
        if (req.answer) {
          req.answer.specialHandlingCodes.forEach((requirementShc) => {
            const existing = shcCodes.find(
              (shc) => shc.shcId === requirementShc.shcId,
            );
            if (!existing) {
              shcCodes.push(requirementShc);
            }
          });
        }
      });
    }
    return shcCodes;
  }

  static fillAirportAutocompleteOptions (airports: AirportLight[]) {
    return airports.map((item) => {
      const keywords = [
        item.airportCode,
        item.cityCode,
        ...item.airportName.split(" "),
        ...item.cityName.split(" "),
      ].filter((keyword) => keyword.length > 2);

      return {
        "label": `${item?.airportCode} - ${item.cityName} ${item.airportName}`,
        "value": item?.airportCode,
        "group": `${[item?.cityCode, item?.cityName]
          .filter((str) => str?.length > 0)
          .join(" - ")} (All Airports)`,
        "groupValue": item?.cityCode,
        keywords,
      };
    });
  }

  /**
   * mutates some fields of a quote that are related to a selected flight
   * from api-search. Note that not all quote properties are related to a
   * selected flight, and will have to be set outside this function
   * @param quote
   * @param bookedFlight
   */
  //
  static setQuotePropertiesRelatedToSelectedFlight(
    quote: Quote,
    bookedFlight: FlightInput
  ): void {
    quote.flightUUID = bookedFlight.flightUUID;
    quote.awbDisabled = bookedFlight.features?.awbDisabled;
    quote.isAllotmentDisabled = bookedFlight.features?.allotment === false;
    quote.isAwbCommentDisabled = bookedFlight.features?.awbCommentDisabled;
    quote.latestAcceptanceTime = bookedFlight.latestAcceptanceTime
      ? new Date(bookedFlight.latestAcceptanceTime)
      : new Date(bookedFlight.selectedRate.latestAcceptanceTime);
    quote.timeOfAvailability = bookedFlight.timeOfAvailability
      ? new Date(bookedFlight.timeOfAvailability)
      : new Date(bookedFlight.selectedRate.timeOfAvailability);
    quote.handlingInfoLink = bookedFlight.handlingInfoLink;
    quote.maxAttachmentCount =
      bookedFlight.features?.maxAttachmentCount == null
        ? 3
        : bookedFlight.features?.maxAttachmentCount;
    quote.maxAttachmentTotalSize =
      bookedFlight.features?.maxAttachmentTotalSize == null
        ? 8900000
        : bookedFlight.features?.maxAttachmentTotalSize;
    quote.airlineBrand = bookedFlight.airlineCompany;
    quote.source = bookedFlight.source;
    quote.lastUpdatedDate = quote.quoteDate = new Date();

    this.setQuoteRateDetailFromSelectedFlight(quote, bookedFlight);

    quote.quoteCurrency = bookedFlight.selectedRate.currency;
    quote.suggestedPrice = bookedFlight.selectedRate.allInRate;
    quote.rateId = bookedFlight.selectedRate.id;
    quote.allotmentCode = bookedFlight.selectedRate.allotmentCode;

    quote.airlineConditions = bookedFlight.airlineConditions;
    quote.salesConditions = bookedFlight.salesConditions;
  }

  static setQuoteRateDetailFromSelectedFlight(quote: Quote, bookedFlight: FlightInput): void {
    if (!bookedFlight.selectedRate) {
      return;
    }

    quote.rateDetail = new QuoteRateDetail();
    quote.rateDetail.name =
      bookedFlight.selectedRate.type.charAt(0).toUpperCase() +
      bookedFlight.selectedRate.type.slice(1) +
      ' Rate ' +
      bookedFlight.selectedRate?.name;
    quote.rateDetail.currency = bookedFlight.selectedRate.currency;
    quote.rateDetail.allInRate = bookedFlight.selectedRate.allInRate;
    quote.rateDetail.total = bookedFlight.selectedRate.total;
    quote.rateDetail.weightType = RateWeightTypeEnum.CHARGEABLE_WEIGHT;
    quote.rateDetail.minimumRate = bookedFlight.selectedRate.minimumRate;
    quote.rateDetail.rateName = bookedFlight.selectedRate?.name;
    quote.rateDetail.spotQuoteRate =
      bookedFlight.selectedRate.isSpotQuoteRate;

    if (bookedFlight.selectedRate?.charges) {
      quote.rateDetail.rateSurcharges =
        bookedFlight.selectedRate?.charges.map(
          (rate) =>
            ({
              surchargeName: rate.label,
              surchargeCode: rate.code,
              formulaKg: rate.rate,
              weightType: rate.type,
              airlineBrand: bookedFlight.airlineCompany,
              minAmount: rate.minAmount,
            }) as Surcharges,
        ) as any[];
      quote.rateDetail.netRate = bookedFlight.selectedRate.netRate;
      quote.rateDetail.rateType = RateTypeEnum.NET_RATE;
      quote.rateDetail.rate = bookedFlight.selectedRate.netRate;
    } else {
      quote.rateDetail.netRate = bookedFlight.selectedRate.netRate;
      quote.rateDetail.rateType = RateTypeEnum.ALL_IN_RATE;
      quote.rateDetail.rate = bookedFlight.selectedRate.allInRate;
    }
    quote.rateDetail.ocdc = bookedFlight.selectedRate.ocdc;
  }
}
