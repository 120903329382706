import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { ICompany } from '../../model/company.model';
import { IOffice } from '../../model/office.model';
import { ErrorUtil } from '../../utils/error.util';
import { API_CORE } from '../../constant/api-core.const';
import { PermissionSetName } from '../../enum';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  async getCompany(): Promise<ICompany> {
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Company.CurrentCompany,
      {},
    ).catch(ErrorUtil.handleError);
  }

  async getOfficesByCompanyId(companyId: number): Promise<IOffice[]> {
    const url = API_CORE.URLs.Company.OfficesById.replace(
      '{companyId}',
      companyId.toString(),
    );
    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.handleError);
  }

  async getCompanyOffices(
    token?: string,
    roleName?: PermissionSetName,
  ): Promise<IOffice[]> {
    const queryStringParameters: any = {};
    if (token) {
      queryStringParameters.token = token;
    }
    if (roleName) {
      queryStringParameters.roleName = roleName;
    }

    return API.get(API_CORE.name, API_CORE.URLs.Company.Offices, {
      queryStringParameters,
    }).catch(ErrorUtil.handleError);
  }

  async getCompanyOfficesFromIataCass(iatacass: string): Promise<IOffice[]> {
    const url = API_CORE.URLs.Company.OfficesByIataCass.replace(
      '{iatacass}',
      iatacass.toString(),
    );
    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.handleError);
  }

  async checkIataCassExist(
    iata: string,
    cass: string,
    token: string,
  ): Promise<boolean> {
    const extraOptions = {
      response: true,
      queryStringParameters: { iata, cass, token },
    };
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Company.OfficeStatus,
      extraOptions,
    )
      .then((response) => response.status === 200)
      .catch(ErrorUtil.handleError);
  }

  async getOffices(companyId: number, token: string): Promise<IOffice[]> {
    const extraOptions = {
        queryStringParameters: { token },
      },
      url = API_CORE.URLs.Company.OfficesById.replace(
        '{companyId}',
        companyId.toString(),
      );
    return API.get(API_CORE.name, url, extraOptions).catch(
      ErrorUtil.handleError,
    );
  }

  async getCompanies(
    type: string,
    haveAirlineBrands: boolean,
    token: string,
  ): Promise<ICompany[]> {
    const extraOptions = {
      header: {
        Authorization: `Bearer ${token}`,
      },
      queryStringParameters: { airline: haveAirlineBrands, type, token },
    };
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Company.Companies,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }

  async getCompaniesLight(type: string, token: string): Promise<ICompany[]> {
    const extraOptions = {
      header: {
        Authorization: `Bearer ${token}`,
      },
      queryStringParameters: { mode: 'LIGHT', type, token },
    };
    return API.get(
      API_CORE.name,
      API_CORE.URLs.Company.Companies,
      extraOptions,
    ).catch(ErrorUtil.handleError);
  }

  async getCompaniesByDomain(domain: string): Promise<ICompany[]> {
    const url = API_CORE.URLs.Company.CompaniesByDomain.replace(
      '{domain}',
      domain,
    );
    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.handleError);
  }

  async updateOfficeById(
    officeId: number,
    updateOffice: IOffice,
  ): Promise<IOffice> {
    const url = API_CORE.URLs.Company.OfficesByOfficeId.replace(
      '{officeId}',
      officeId.toString(),
    );
    return API.put(API_CORE.name, url, {
      body: updateOffice,
    }).catch(ErrorUtil.handleError);
  }

  async updateCompanyById(
    companyId: number,
    updateCompany: ICompany,
  ): Promise<ICompany> {
    const url = API_CORE.URLs.Company.CompaniesById.replace(
      '{companyId}',
      companyId.toString(),
    );
    return API.put(API_CORE.name, url, {
      body: updateCompany,
    }).catch(ErrorUtil.handleError);
  }
}
