export enum CommodityType {
  DANGEROUS_GOODS = "Dangerous Goods",
  ALL = "All",
  GENERAL_CARGO = "General Cargo",
  HUMAN_REMAINS = "Human Remains",
  LIVE_ANIMAL = "Live Animals",
  OTHERS = "Others",
  PERISHABLE = "Perishable",
  PHARMACEUTICALS = "Pharmaceuticals",
  VALUABLES = "Valuables",
}
