<div class="cargomate">
  <div class="cargomate__header">
    <span class="title" i18n="@@global.cargopilot-rate-insights"
      >Cargo CoPilot - Rate Insights</span
    >
    <div
      class="date-selector"
      *ngIf="!isCargoMartProEnabled || isCopilotActivated; else proTag"
    >
      <div [ngbPopover]="dropdown" placement="bottom">
        <span>{{ selectedDate }}</span>
        <span class="caret">
          <svg
            width="10"
            height="6"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.00787 9C7.36059 9 11.6848 2.34803 10.9072 1.59336C10.1296 0.838686 1.96048 0.766564 1.10857 1.59336C0.256657 2.4212 4.65623 9 6.00787 9Z"
              [attr.fill]="'#679EC6'"
              [attr.stroke]="'#679EC6'"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <ng-template #dropdown>
        <div class="date-selector-menu">
          <ng-container *ngFor="let date of dates">
            <div class="option" (click)="select(date)">{{ date }}</div>
          </ng-container>
        </div>
      </ng-template>
    </div>
    <img class="tagImg" src="/assets/media/icons/pro-tag.svg" alt="" />

    <ng-template #proTag>
      <img
        class="protag-img"
        src="/assets/media/icons/pro-tag.svg"
        (click)="showUpgradeMessage()"
        alt="Pro upgrade tag"
      />
    </ng-template>
  </div>

  <ng-container>
    <div *ngIf="!isCargoMartProEnabled || isCopilotActivated">
      <div
        class="cargomate__body"
        *ngIf="
          (!isCargoMartProEnabled || isCopilotActivated) &&
          !isLoading &&
          showRateDetails
        "
      >
        <div class="headline" i18n="@@global.info-from-rate-sheets">
          Information from Rate Sheets
        </div>
        <div>
          <table class="rate-metadata">
            <tr>
              <td i18n="@@cargomate.currency">Currency</td>
              <td>{{ currency }}</td>
            </tr>
            <tr *ngIf="!!insight?.promoRate">
              <td i18n="@@cargomate.promo-validity">Promo validity</td>
              <td>{{ promoValidity }}</td>
            </tr>
          </table>
          <div i18n="@@cargomate.rate-breakdown" class="rate-breakdown">
            Rate Breakdown
          </div>
          <table class="rate-table">
            <tr class="rate-type">
              <td class="weight-category"></td>
              <td *ngIf="!!insight?.marketRate">
                <div class="rate-type__market" i18n="@@cargomate.market">
                  Market
                </div>
              </td>
              <td *ngIf="!!insight?.contractRate">
                <div class="rate-type__contract" i18n="@@cargomate.contract">
                  Contract
                </div>
              </td>
              <td *ngIf="!!insight?.promoRate">
                <div class="rate-type__promo" i18n="@@cargomate.promo">
                  Promo
                </div>
              </td>
              <td *ngIf="!!insight?.averageRate">
                <div class="rate-type__average" i18n="@@cargomate.average">
                  Average
                </div>
              </td>
            </tr>
            <tr>
              <td class="weight-category" i18n="@@cargomate.min">MIN</td>
              <td *ngIf="!!insight?.marketRate">
                <div>{{ getRateValue(insight?.marketRate, "M") }}</div>
              </td>
              <td *ngIf="!!insight?.contractRate">
                <div>{{ getRateValue(insight?.contractRate, "M") }}</div>
              </td>
              <td *ngIf="!!insight?.promoRate">
                <div>{{ getRateValue(insight?.promoRate, "M") }}</div>
              </td>
              <td *ngIf="!!insight?.averageRate">
                <div>{{ getRateValue(insight?.averageRate, "M") }}</div>
              </td>
            </tr>
            <tr>
              <td class="weight-category" i18n="@@cargomate.nominal">NOM</td>
              <td *ngIf="!!insight?.marketRate">
                <div>{{ getRateValue(insight?.marketRate, "N") }}</div>
              </td>
              <td *ngIf="!!insight?.contractRate">
                <div>{{ getRateValue(insight?.contractRate, "N") }}</div>
              </td>
              <td *ngIf="!!insight?.promoRate">
                <div>{{ getRateValue(insight?.promoRate, "N") }}</div>
              </td>
              <td *ngIf="!!insight?.averageRate">
                <div>{{ getRateValue(insight?.averageRate, "N") }}</div>
              </td>
            </tr>
            <tr>
              <td class="weight-category">45-KG</td>
              <td
                *ngIf="!!insight?.marketRate"
                [class.highlighted]="shouldHighlight(45, 100)"
              >
                <div>
                  {{ getRateValue(insight?.marketRate, "KG", 45) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.contractRate"
                [class.highlighted]="shouldHighlight(45, 100)"
              >
                <div>
                  {{ getRateValue(insight?.contractRate, "KG", 45) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.promoRate"
                [class.highlighted]="shouldHighlight(45, 100)"
              >
                <div>
                  {{ getRateValue(insight?.promoRate, "KG", 45) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.averageRate"
                [class.highlighted]="shouldHighlight(45, 100)"
              >
                <div>{{ getRateValue(insight?.averageRate, "KG", 45) }}</div>
              </td>
            </tr>
            <tr>
              <td class="weight-category">100-KG</td>
              <td
                *ngIf="!!insight?.marketRate"
                [class.highlighted]="shouldHighlight(100, 300)"
              >
                <div>
                  {{ getRateValue(insight?.marketRate, "KG", 100) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.contractRate"
                [class.highlighted]="shouldHighlight(100, 300)"
              >
                <div>
                  {{ getRateValue(insight?.contractRate, "KG", 100) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.promoRate"
                [class.highlighted]="shouldHighlight(100, 300)"
              >
                <div>
                  {{ getRateValue(insight?.promoRate, "KG", 100) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.averageRate"
                [class.highlighted]="shouldHighlight(100, 300)"
              >
                <div>{{ getRateValue(insight?.averageRate, "KG", 100) }}</div>
              </td>
            </tr>
            <tr>
              <td class="weight-category">300-KG</td>
              <td
                *ngIf="!!insight?.marketRate"
                [class.highlighted]="shouldHighlight(300, 500)"
              >
                <div>
                  {{ getRateValue(insight?.marketRate, "KG", 300) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.contractRate"
                [class.highlighted]="shouldHighlight(300, 500)"
              >
                <div>
                  {{ getRateValue(insight?.contractRate, "KG", 300) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.promoRate"
                [class.highlighted]="shouldHighlight(300, 500)"
              >
                <div>
                  {{ getRateValue(insight?.promoRate, "KG", 300) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.averageRate"
                [class.highlighted]="shouldHighlight(300, 500)"
              >
                <div>{{ getRateValue(insight?.averageRate, "KG", 300) }}</div>
              </td>
            </tr>
            <tr>
              <td class="weight-category">500-KG</td>
              <td
                *ngIf="!!insight?.marketRate"
                [class.highlighted]="shouldHighlight(500, 1000)"
              >
                <div>
                  {{ getRateValue(insight?.marketRate, "KG", 500) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.contractRate"
                [class.highlighted]="shouldHighlight(500, 1000)"
              >
                <div>
                  {{ getRateValue(insight?.contractRate, "KG", 500) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.promoRate"
                [class.highlighted]="shouldHighlight(500, 1000)"
              >
                <div>
                  {{ getRateValue(insight?.promoRate, "KG", 500) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.averageRate"
                [class.highlighted]="shouldHighlight(500, 1000)"
              >
                <div>{{ getRateValue(insight?.averageRate, "KG", 500) }}</div>
              </td>
            </tr>
            <tr>
              <td class="weight-category">1000-KG</td>
              <td
                *ngIf="!!insight?.marketRate"
                [class.highlighted]="shouldHighlight(1000, 3000)"
              >
                <div>
                  {{ getRateValue(insight?.marketRate, "KG", 1000) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.contractRate"
                [class.highlighted]="shouldHighlight(1000, 3000)"
              >
                <div>
                  {{ getRateValue(insight?.contractRate, "KG", 1000) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.promoRate"
                [class.highlighted]="shouldHighlight(1000, 3000)"
              >
                <div>
                  {{ getRateValue(insight?.promoRate, "KG", 1000) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.averageRate"
                [class.highlighted]="shouldHighlight(1000, 3000)"
              >
                <div>{{ getRateValue(insight?.averageRate, "KG", 1000) }}</div>
              </td>
            </tr>
            <tr>
              <td class="weight-category">3000-KG</td>
              <td
                *ngIf="!!insight?.marketRate"
                [class.highlighted]="shouldHighlight(3000)"
              >
                <div>
                  {{ getRateValue(insight?.marketRate, "KG", 3000) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.contractRate"
                [class.highlighted]="shouldHighlight(3000)"
              >
                <div>
                  {{ getRateValue(insight?.contractRate, "KG", 3000) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.promoRate"
                [class.highlighted]="shouldHighlight(3000)"
              >
                <div>
                  {{ getRateValue(insight?.promoRate, "KG", 3000) }}
                </div>
              </td>
              <td
                *ngIf="!!insight?.averageRate"
                [class.highlighted]="shouldHighlight(3000)"
              >
                <div>{{ getRateValue(insight?.averageRate, "KG", 3000) }}</div>
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="!!insight?.generalConditions">
          <div class="headline" i18n="@@global.gen-conds">
            General Conditions
          </div>
          <div class="gen-conds">
            <div class="disclaimer" [innerHtml]="getGeneralConditions()"></div>
          </div>
        </div>
        <div *ngIf="!!ghaName || !!ghaAddress">
          <div class="headline" i18n="@@global.shipment-handling">
            Shipment Handling
          </div>
          <table class="gha-info">
            <tr *ngIf="!!ghaName">
              <td i18n="@@cargomate.gha-name">Gha Name</td>
              <td>{{ ghaName }}</td>
            </tr>
            <tr *ngIf="!!ghaAddress">
              <td i18n="@@cargomate.gha-address">Gha Address</td>
              <td>{{ ghaAddress }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div
        *ngIf="
          (!isCargoMartProEnabled || isCopilotActivated) &&
          !isLoading &&
          !showRateDetails
        "
        class="no-rate-insight"
        i18n="@@cargomate.sorry-no-rate-insights"
      >
        Sorry, it seems that Rate Insights are not available for the selected
        criteria
      </div>
      <div class="loader-line" *ngIf="isLoading"></div>
    </div>
    <img
      *ngIf="!isCopilotActivated && isCargoMartProEnabled"
      src="/assets/media/icons/search-results/rate-insights-blur.png"
    />
  </ng-container>
</div>
