import { Injectable } from "@angular/core";
import {
  EawbDetailsWithError,
  IDocumentExport,
  IEawbDetails,
  IFhlDetails,
  QuoteRequestStatusEnum,
  QuoteService,
  QuoteStatusEnum,
} from "@cai-services";
import { from, Observable } from "rxjs";
import { PresignedQuoteAttachment } from "../_models/presigned-quote-attachment.model";
import { PresignedURL } from "../_models/presigned-url.model";
import PrintEawbInformations from "../_models/print-eawb-informations.model";
import { QuoteAttachment } from "../_models/quote-attachment.model";
import { Quote } from "../_models/quote.model";

@Injectable({
  "providedIn": "root",
})
export class AppQuoteService {
  constructor (private readonly quoteService: QuoteService) {}

  getQuotes (
    statusOption: {
      inRequestStatus: QuoteRequestStatusEnum[];
      inStatus: QuoteStatusEnum[];
      hasRating?: boolean;
    },
    page: number,
    size: number,
    sort: string | string[],
    filterOptions?: { [key: string]: any }
  ): Observable<any> {
    return from(
      this.quoteService.getQuotes(
        page,
        size,
        sort,
        filterOptions?.type,
        statusOption?.inStatus,
        statusOption?.inRequestStatus,
        statusOption?.hasRating,
        filterOptions?.frequentFilter,
        filterOptions?.origin,
        filterOptions?.destination,
        filterOptions?.commodity,
        filterOptions?.airline,
        filterOptions?.fromDate,
        filterOptions?.toDate,
        undefined,
        filterOptions?.forwarder,
        filterOptions?.inBookingType,
        filterOptions?.search
      )
    );
  }

  subscribeTracking (quoteId: number): Promise<void> {
    return this.quoteService.subscribeTrackTrace(quoteId);
  }

  unsubscribeTracking (quoteId: number): Promise<void> {
    return this.quoteService.unSubscribeTrackTrace(quoteId);
  }

  addEmailTrackTrace (quoteId: number, email: string): Promise<void> {
    return this.quoteService.addEmailTrackTrace(quoteId, email);
  }

  retrieveQuoteById (quoteId: number, token?: string): Promise<any> {
    return this.quoteService.retrieveQuoteById(quoteId, token);
  }

  getShipmentSummaryAsPDF (quote: Quote): Observable<any> {
    return from(this.quoteService.exportPDFForShipmentSummary(quote));
  }

  getSubscribedEmails (quoteId: number): Promise<string[]> {
    return this.quoteService.getSubscribedEmails(quoteId);
  }

  createFwb (
    quoteId: number,
    details: IEawbDetails[],
    sendFwb: boolean
  ): Promise<EawbDetailsWithError> {
    return this.quoteService.createFwb(quoteId, details, sendFwb);
  }

  createFhl (
    quoteId: number,
    details: IFhlDetails,
    sendFhl: boolean
  ): Promise<EawbDetailsWithError> {
    return this.quoteService.createFhl(quoteId, details, sendFhl);
  }

  eawbExportPdf (
    printEawbInformation: PrintEawbInformations
  ): Promise<IDocumentExport> {
    return this.quoteService.eawbExportPdf(printEawbInformation);
  }

  deleteFhl (quoteId: number, details: IFhlDetails): Promise<void> {
    return this.quoteService.deleteFhl(quoteId, details);
  }

  getFwbDetails (quoteId: number, token?: string): Promise<IEawbDetails[]> {
    return this.quoteService.getFwbDetails(quoteId, token);
  }

  getFhlDetails (quoteId: number, token?: string): Promise<IEawbDetails[]> {
    return this.quoteService.getFhlDetails(quoteId, token);
  }

  generateUploadUrlForAttachment (
    quoteAttachment: QuoteAttachment
  ): Promise<PresignedQuoteAttachment> {
    if (!quoteAttachment) {
      throw new Error(
        "Required parameter body was null or undefined when calling generate url for attachment."
      );
    }
    return this.quoteService.generateUploadUrlForAttachment(
      quoteAttachment
    ) as Promise<PresignedQuoteAttachment>;
  }

  generateUploadUrlForQuoteAttachment (
    quoteId: number,
    quoteAttachment: QuoteAttachment
  ): Promise<PresignedQuoteAttachment> {
    if (!quoteId || !quoteAttachment) {
      throw new Error(
        "Required parameter body was null or undefined when calling generate url for quote attachment."
      );
    }
    return this.quoteService.generateUploadUrlForQuoteAttachment(
      quoteId,
      quoteAttachment
    ) as Promise<PresignedQuoteAttachment>;
  }

  generateDownloadUrlForAttachment (
    quoteAttachmentId: number,
    token?: string
  ): Promise<PresignedURL> {
    if (!quoteAttachmentId) {
      throw new Error(
        "Required parameter body was null or undefined when calling get attachment."
      );
    }
    return this.quoteService.generateDownloadUrlForAttachment(
      quoteAttachmentId,
      token
    ) as Promise<PresignedURL>;
  }

  generateDownloadUrlForQuoteAttachment (
    quoteId: number,
    quoteAttachmentId: number,
    token?: string
  ): Promise<PresignedURL> {
    if (!quoteId || !quoteAttachmentId) {
      throw new Error(
        "Required parameter body was null or undefined when calling get quote attachment."
      );
    }
    return this.quoteService.generateDownloadUrlForQuoteAttachment(
      quoteId,
      quoteAttachmentId,
      token
    ) as Promise<PresignedURL>;
  }

  deleteAttachment (quoteAttachmentId: number): Promise<PresignedURL> {
    if (!quoteAttachmentId) {
      throw new Error(
        "Required parameter body was null or undefined when calling delete quote attachment."
      );
    }
    return this.quoteService.deleteAttachment(
      quoteAttachmentId
    ) as Promise<PresignedURL>;
  }

  deleteQuoteAttachment (
    quoteId: number,
    quoteAttachmentId: number
  ): Promise<PresignedURL> {
    if (!quoteId || !quoteAttachmentId) {
      throw new Error(
        "Required parameter body was null or undefined when calling delete quote attachment."
      );
    }
    return this.quoteService.deleteQuoteAttachment(
      quoteId,
      quoteAttachmentId
    ) as Promise<PresignedURL>;
  }

  markAttachmentUploadSuccessful (quoteAttachmentId: number): Promise<void> {
    if (!quoteAttachmentId) {
      throw new Error(
        "Required parameter body was null or undefined when calling patch attachment."
      );
    }
    return this.quoteService.markAttachmentUploadSuccessful(
      quoteAttachmentId
    ) as Promise<void>;
  }

  markQuoteAttachmentUploadSuccessful (
    quoteId: number,
    quoteAttachmentId: number
  ): Promise<void> {
    if (!quoteId || !quoteAttachmentId) {
      throw new Error(
        "Required parameter body was null or undefined when calling patch quote attachment."
      );
    }
    return this.quoteService.markQuoteAttachmentUploadSuccessful(
      quoteId,
      quoteAttachmentId
    ) as Promise<void>;
  }
}
