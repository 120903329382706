const localhostPorts = ['4200', '4201'];

export class BrandIconUtil {
  static isCargoAiDomain(): boolean {
    const url = window.location.hostname,
      subDomain = url.split('.')[0];
    return (
      (url.includes('cargoai.co') && subDomain === 'app') ||
      (url.includes('cargo-wallet.com') && subDomain === 'app') ||
      localhostPorts.includes(window.location.port) ||
      url.includes('localhost')
    );
  }

  static fetchBackgroundIcon(defaultIcon: string, defaultPath: string): string {
    const url = window.location.hostname;
    if (window.location.port === '9876') {
      return defaultIcon;
    }
    if (this.isCargoAiDomain() || this.isCargoAdmin()) {
      return defaultIcon;
    }
    return `${defaultPath}${url.split('.')[0]}.png`;
  }

  static fetchDomainTitle(): string {
    const url = window.location.hostname,
      subDomain = this.isCargoAdmin()
        ? 'cargoadmin'
        : this.isCargoAiDomain()
          ? this.isCargoWallet()
            ? 'cargowallet'
            : 'cargomart'
          : url.split('.')[0];
    return TITLES[subDomain];
  }

  static isCargoWallet(): boolean {
    return (
      window.location.hostname.includes('cargo-wallet') ||
      window.location.hostname.includes('wallet.cargoai') ||
      window.location.port === '4201'
    );
  }
  static isCargoAdmin(): boolean {
    return (
      window.location.hostname.includes('admin') ||
      window.location.port === '4202'
    );
  }
}

export const TITLES = {
  cargoai: 'CargoAi',
  cargomart: 'CargoMART',
  cargowallet: 'CargoWALLET',
  cargoadmin: 'CargoADMIN',
  knaog: 'KNAOG',
  airindia: 'Air India',
  aireuropa: 'Air Europa',
  magaya: 'Magaya',
  azfreight: 'AZFreight',
  awbeditor: 'Awb Editor',
  ccn: 'CCN',
  aeroafrica: 'Aero-Africa',
  freightify: 'Freightify',
  craft: 'Craft',
  gofreight: 'GoFreight',
  maskargo: 'Maskargo',
  aeromexico: 'Aeromexico',
  ita: 'ITA Airways',
  cargonation: 'CargoNation',
  inditex: 'Inditex',
  logisys: 'Logi-Sys',
  nap: 'Neutral Air Partner',
  hkiacargo: 'HKIA Cargo',
  'df-alliance': 'DF Alliance',
};

export const CONTACT_US_LINKS = {
  cargoai: 'https://www.cargoai.co/contact-us/',
  lot: 'https://www.lot.com/us/en/help-center/contact',
  knaog: 'https://sg.kuehne-nagel.com/',
  airindia: 'https://www.airindia.in/customer-support.htm',
  aireuropa: 'http://cargo.air-europa.com/consultas.html',
  magaya: 'https://www.magaya.com/contact/',
  azfreight: 'https://azfreight.com/contact-us/',
  awbeditor: 'https://web.awbeditor.com/contact',
  ccn: 'https://www.ccnhub.com/contact-us/',
  aeroafrica: 'https://aero-africa.com/service-centers/',
  freightify: 'https://www.freightify.com/contact-us',
  craft: 'https://e-craft.com/en/contact/',
  gofreight: 'https://www.gofreight.com/contact-us',
  maskargo: 'https://www.maskargo.com/contact-us',
  aeromexico: 'https://amcargo.aeromexico.com/en/contacto',
  ita: 'https://www.ita-airways.com/en_en/support/contact-assistance/customer-center.html',
  cargonation: 'https://cargonation.aero/#contact',
  inditex: 'https://www.inditex.com/itxcomweb/en/info/contact-us',
  logisys: 'https://www.logi-sys.com/contact-us',
  nap: 'https://neutralairpartner.com/contact',
  hkiacargo: 'https://www.hkaircargo.com/',
  'df-alliance': 'https://www.df-alliance.com/company/contact-us',
};
