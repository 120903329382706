import { Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { ErrorUtil } from '../../utils/error.util';
import { API_CORE } from '../../constant/api-core.const';
import {
  IOffice,
  IOfficeInvitation,
  IOfficeCharge,
  IOfficeTermsAndConditions,
  IPaginationResults,
  IRateMetadata,
  IUser,
  PatchOfficeMarginRequest,
  UpdateOfficeMemberRequest,
  CreateOrUpdateOfficeChargeRequest,
  CreateOrUpdateOfficeTermsRequest,
  CreateOrUpdateOfficeCustomerRequest,
  CreateOfficeAirportChargeRequest,
  IOfficeAirportCharge,
} from '../../model';

@Injectable({
  providedIn: 'root',
})
export class OfficeService {
  async getLatestServiceChargesPdfUrlOfCoLoaderOffice(
    coLoaderOfficeId: number,
  ): Promise<string> {
    const url = API_CORE.URLs.Office.Terms.replace(
      '{officeId}',
      coLoaderOfficeId.toString(),
    );
    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.warnError);
  }

  async getDisclaimerOfCoLoaderOffice(
    coLoaderOfficeId: number,
  ): Promise<string> {
    const url = API_CORE.URLs.Office.Disclaimer.replace(
      '{officeId}',
      coLoaderOfficeId.toString(),
    );
    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.warnError);
  }
  async getCoLoaderOffice(coLoaderOfficeId: number): Promise<any> {
    const url = API_CORE.URLs.Office.Office.replace(
      '{officeId}',
      coLoaderOfficeId.toString(),
    );
    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.warnError);
  }

  async getOfficeMembers(
    officeId: number,
    page: number,
    size: number,
    sort: string,
    search: string,
  ): Promise<IPaginationResults<IUser>> {
    const url = API_CORE.URLs.Office.Members.replace(
        '{officeId}',
        officeId.toString(),
      ),
      queryStringParameters: any = {
        page,
        size,
        sort,
      };

    if (search && search.length > 0) {
      queryStringParameters.search = search;
    }

    return API.get(API_CORE.name, url, { queryStringParameters }).catch(
      ErrorUtil.warnError,
    );
  }

  async getOfficeAirportCharges(
    officeId: number,
  ): Promise<IOfficeAirportCharge[]> {
    const url = API_CORE.URLs.Office.Charges.replace(
      '{officeId}',
      officeId.toString(),
    );

    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.warnError);
  }

  async getOfficeCharges(
    officeId: number,
    airportCode: string,
    page: number,
    size: number,
    sort: string,
  ): Promise<IPaginationResults<IOfficeCharge>> {
    const url = API_CORE.URLs.Office.AirportCharges.replace(
        '{officeId}',
        officeId.toString(),
      ).replace('{airportCode}', airportCode),
      queryStringParameters: any = {
        page,
        size,
        sort,
      };

    return API.get(API_CORE.name, url, { queryStringParameters }).catch(
      ErrorUtil.warnError,
    );
  }

  async getOfficeTermsAndConditions(
    officeId: number,
  ): Promise<IOfficeTermsAndConditions[]> {
    const url = API_CORE.URLs.Office.TermsAndConditions.replace(
      '{officeId}',
      officeId.toString(),
    );

    return API.get(API_CORE.name, url, {}).catch(ErrorUtil.warnError);
  }

  async addOfficeMember(
    officeId: number,
    email: string,
    roleNames: string[],
  ): Promise<{ isUserAdded: boolean; isUserInvited: boolean }> {
    const url = API_CORE.URLs.Office.Members.replace(
      '{officeId}',
      officeId.toString(),
    );

    return API.post(API_CORE.name, url, {
      body: {
        email,
        roleNames,
      },
    });
  }

  async removeUserFromOffice(
    officeId: number,
    userId: number,
    disable?: boolean,
  ): Promise<void> {
    const url = API_CORE.URLs.Office.Member.replace(
      '{officeId}',
      officeId.toString(),
    ).replace('{userId}', userId.toString());

    const extraOptions: any = {
      queryStringParameters: {},
    };
    if (disable) {
      extraOptions.queryStringParameters.disable = disable;
    }
    return API.del(API_CORE.name, url, extraOptions);
  }

  async updateUserFromOffice(
    officeId: number,
    userId: number,
    updateOfficeMemberRequest: UpdateOfficeMemberRequest,
  ) {
    const url = API_CORE.URLs.Office.Member.replace(
      '{officeId}',
      officeId.toString(),
    ).replace('{userId}', userId.toString());

    return API.put(API_CORE.name, url, { body: updateOfficeMemberRequest });
  }

  async getInvitation(invitationId: string): Promise<IOfficeInvitation> {
    const url = API_CORE.URLs.Office.Invitation.replace(
      '{invitationId}',
      invitationId,
    );

    return API.get(API_CORE.name, url, {});
  }

  async acceptInvitation(invitationId: string, user: any) {
    const url = API_CORE.URLs.Office.AcceptInvitation.replace(
      '{invitationId}',
      invitationId,
    );

    return API.post(API_CORE.name, url, { body: user });
  }

  async patchOfficeMargin(
    officeId: number,
    patchOfficeMarginRequest: PatchOfficeMarginRequest,
  ) {
    const url = API_CORE.URLs.Office.Margin.replace(
      '{officeId}',
      officeId.toString(),
    );

    return API.patch(API_CORE.name, url, { body: patchOfficeMarginRequest });
  }

  async createOfficeAirportCharge(
    officeId: number,
    request: CreateOfficeAirportChargeRequest,
  ): Promise<void> {
    const url = API_CORE.URLs.Office.Charges.replace(
      '{officeId}',
      officeId.toString(),
    );

    return API.post(API_CORE.name, url, { body: request });
  }

  async createOrUpdateOfficeService(
    officeId: number,
    airportCode: string,
    name: string,
    request: CreateOrUpdateOfficeChargeRequest,
  ) {
    const url = API_CORE.URLs.Office.AirportCharge.replace(
      '{officeId}',
      officeId.toString(),
    )
      .replace('{airportCode}', airportCode)
      .replace('{name}', encodeURIComponent(name));

    return API.put(API_CORE.name, url, { body: request });
  }

  async deleteOfficeCharge(
    officeId: number,
    airportCode: string,
    name: string,
  ) {
    const url = API_CORE.URLs.Office.AirportCharge.replace(
      '{officeId}',
      officeId.toString(),
    )
      .replace('{airportCode}', airportCode)
      .replace('{name}', encodeURIComponent(name));

    return API.del(API_CORE.name, url, {});
  }

  async patchOfficeAirportChargeCurrency(
    officeId: number,
    airportCode: string,
    currencyCode: string,
  ): Promise<void> {
    const url = API_CORE.URLs.Office.AirportCharges.replace(
      '{officeId}',
      officeId.toString(),
    ).replace('{airportCode}', airportCode);

    return API.patch(API_CORE.name, url, { body: { currencyCode } });
  }

  async createOrUpdateOfficeTerms(
    officeId: number,
    name: string,
    request: CreateOrUpdateOfficeTermsRequest,
  ) {
    const url = API_CORE.URLs.Office.TermsAndCondition.replace(
      '{officeId}',
      officeId.toString(),
    ).replace('{name}', encodeURIComponent(name));

    return API.put(API_CORE.name, url, { body: request });
  }

  async deleteOfficeTerms(officeId: number, name: string) {
    const url = API_CORE.URLs.Office.TermsAndCondition.replace(
      '{officeId}',
      officeId.toString(),
    ).replace('{name}', encodeURIComponent(name));

    return API.del(API_CORE.name, url, {});
  }

  async getOfficeCustomers(officeId: number) {
    const url = API_CORE.URLs.Office.Customers.replace(
      '{officeId}',
      officeId.toString(),
    );

    return API.get(API_CORE.name, url, {});
  }

  async createOrUpdateOfficeCustomers(
    officeId: number,
    request: CreateOrUpdateOfficeCustomerRequest,
  ) {
    const url = API_CORE.URLs.Office.Customers.replace(
      '{officeId}',
      officeId.toString(),
    );

    return API.post(API_CORE.name, url, { body: request });
  }

  async getWalletsMetadata(): Promise<IRateMetadata> {
    return API.get(
      API_CORE.name,
      `${API_CORE.URLs.Office.OfficesWithWalletMetadata}`,
      null,
    );
  }

  async getWalletsPaginated(
    page: number,
    size: number,
    sort: string,
    countries?: string,
    walletStatuses?: string,
    search?: string,
  ): Promise<IOffice[]> {
    const extraOptions: any = {
      queryStringParameters: {
        page,
        size,
        sort,
      },
    };
    if (countries) {
      extraOptions.queryStringParameters.country = countries;
    }
    if (walletStatuses) {
      extraOptions.queryStringParameters.walletStatus = walletStatuses;
    }
    if (search) {
      extraOptions.queryStringParameters.search = search;
    }
    return API.get(
      API_CORE.name,
      `${API_CORE.URLs.Office.OfficesWithWalletPaginated}`,
      extraOptions,
    );
  }
}
