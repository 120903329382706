import { Component, Input } from '@angular/core';

@Component({
  selector: 'kt-svg-quality-score',
  templateUrl: './svg-quality-score.component.html',
  styleUrls: ['./svg-quality-score.component.scss'],
})
export class SvgQualityScoreComponent {
  @Input() qualityScore: number;
  @Input() avgQualityScore: number;
}
