import {
  RateTypeEnum,
  IQuoteRateDetail,
  RateWeightTypeEnum,
} from '@cai-services';
import { Surcharges } from './surcharges.model';
import { Charge } from './flight-rate.model';

export class QuoteRateDetail implements IQuoteRateDetail {
  id: number;
  name: string;
  rateId?: number;
  rateType: RateTypeEnum;
  rateName: string;
  rate: number;
  allInRate: number;
  netRate: number;
  total: number;
  previousRate: number;
  currency: string;
  weightType: RateWeightTypeEnum;
  minimumRate: boolean;
  rateSurcharges: Surcharges[];
  ocdc: Charge[];
  weight?: number;
  walletBookingFees: number;
  walletExportFees: number;
  totalWithCharges: number;
  spotQuoteRate: boolean;

  public constructor(init?: Partial<QuoteRateDetail>) {
    Object.assign(this, init);
  }
}
