<div class="wrapper">
  <div class="dropdown">
    <input
      #input
      class="select-trigger"
      [placeholder]="
        placeholder || allowNoneSelected
          ? (clonedOptions?.length).toString().concat(' options')
          : ''
      "
      [class.invalid]="invalid && !isOpen()"
      [ngModel]="inputText"
      [title]="inputText"
      (focus)="onTabPressed(dropdown, input)"
      readonly
    />
    <span
      (click)="open(dropdown, input); $event.stopPropagation()"
      class="caret"
    >
    </span>
    <ng-template #dropdown>
      <div class="multiselect-dropdown" [ngClass]="class">
        <ng-container *ngIf="enableSearch">
          <div
            class="multiselect-search-container"
            (click)="$event.stopPropagation()"
          >
            <input
              [ngModel]="searchKey"
              (ngModelChange)="handleKeywordChange($event)"
              placeholder="Search..."
            />
          </div>
          <span
            class="kt-input-icon__icon kt-input-icon__icon--right"
            (click)="$event.stopPropagation()"
          >
            <span><em class="la la-search"></em></span>
          </span>
        </ng-container>
        <ng-container
          *ngIf="options?.length && clonedOptions?.length; else showDefaultMsg"
        >
          <cdk-virtual-scroll-viewport
            class="multiselect-dropdown-inner"
            [itemSize]="45"
          >
            <div
              class="multiselect-dropdown-item select-all"
              *ngIf="selectAllText"
              [class.selected]="selectedOptions.length === options.length"
              (click)="toggleSelectAll(); $event.stopPropagation()"
            >
              <cai-checkbox [isActive]="isCheckboxActive()"></cai-checkbox>
              <div>{{ selectAllText }} ({{ clonedOptions?.length }})</div>
            </div>
            <div
              *ngFor="let option of clonedOptions"
              class="multiselect-dropdown-item"
              [class.selected]="isSelected(option)"
              (click)="toggleSelect(option); $event.stopPropagation()"
            >
              <cai-checkbox [isActive]="isSelected(option)"></cai-checkbox>
              <div class="label-ellipsis">
                <div
                  class="label"
                  [title]="labelCondition(option)"
                  [innerHtml]="getDisplayLabel(option)"
                ></div>
                <div
                  *ngIf="!hideValue"
                  class="value"
                  [title]="getDisplayDescription(option)"
                  [innerHtml]="getDisplayDescription(option)"
                ></div>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
        </ng-container>
        <ng-template #showDefaultMsg>
          <div class="default-msg" (click)="$event.stopPropagation()">
            <span>{{ this.defaultMsg }}</span>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
