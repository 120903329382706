<h1 i18n="@@office-management.office-management">
  {{ getOfficeName(activeOffice) }}
</h1>
<div class="about-container" *ngIf="isCargoWallet">
  <div class="text-wrapper">About</div>
  <hr width="100%" size="2" />
  <form
    id="office-information-form"
    class="office-settings-form"
    [formGroup]="officeEmailModifierForm"
  >
    <div class="input-container">
      <div class="input-label">
        <div class="field-label">
          <div class="label-text">Office Name</div>
          <img
            *ngIf="!isAdmin(currentUser) && isCargoWallet"
            src="/assets/media/icons/wallet/toolTip.svg"
            class="icon-default"
            alt=""
            matTooltip="Only an Office Admin can edit Office Name"
            matTooltipPosition="above"
          />
        </div>
        <div *ngIf="!isAdmin(currentUser) && isCargoWallet">
          <span class="about-company-info">{{ companyName }}</span>
        </div>
        <div *ngIf="isAdmin(currentUser) && isCargoWallet">
          <input
            matInput
            *ngIf="companyName"
            type="text"
            class="input-field"
            i18n-placeholder="@@office-setting.office-name"
            formControlName="OfficeName"
          />
          <span *ngIf="!companyName" class="about-hypen">-</span>
        </div>
      </div>
      <div class="input-label">
        <div class="field-label">
          <div class="label-text">Finance Office Email</div>
          <img
            *ngIf="!isAdmin(currentUser) && isCargoWallet"
            src="/assets/media/icons/wallet/toolTip.svg"
            class="icon-default"
            alt=""
            matTooltip="Only an Office Admin can edit  Finance Office Email."
            matTooltipPosition="above"
          />
        </div>
        <div *ngIf="!isAdmin(currentUser) && isCargoWallet">
          <span class="about-company-info">{{ companyFinanceEmail }}</span>
        </div>
        <div *ngIf="isAdmin(currentUser) && isCargoWallet">
          <input
            matInput
            *ngIf="companyFinanceEmail"
            type="text"
            class="input-field"
            formControlName="OfficeEmail"
          />
        </div>
        <span *ngIf="!companyFinanceEmail" class="about-hypen">-</span>
      </div>
      <button
        *ngIf="isAdmin(currentUser) && isCargoWallet"
        mat-flat-button
        color="primary"
        i18n="@@office-setting.save"
        aria-label="Save payment approval"
        (click)="onSaveOfficeDetails()"
        class="office-about-save-button"
        [disabled]="!isEdited"
        [ngClass]="{ isedited: isEdited }"
      >
        Save Changes
      </button>
    </div>
  </form>
</div>
<form
  id="office-information-form"
  class="office-settings-form"
  *ngIf="officeInformationForm"
  [formGroup]="officeInformationForm"
>
  <article id="office-information-card" *ngIf="!isCargoWallet">
    <section class="header" [class.editing]="isEditingOfficeInformation">
      <div class="header-office-name">
        <span
          #officeNameInput
          id="office-name-input"
          [contentEditable]="isEditingOfficeInformation"
          [textContent]="officeNameToUpdate"
          (input)="limitOfficeName($event.target)"
        >
        </span>
        <span
          class="office-name-letter-count"
          *ngIf="isEditingOfficeInformation"
          >{{ officeNameInput.innerText.length }}/{{
            maxOfficeNameLength
          }}</span
        >
      </div>

      <button
        id="edit-office-name"
        class="office-settings-icon-button"
        mat-icon-button
        color="primary"
        aria-label="Edit office name"
        *ngIf="iAmAdmin && !isEditingOfficeInformation"
        (click)="startEditingOfficeInformation()"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <div class="pb-3 pt-3" *ngIf="!iAmAdmin" style="margin-top: 2px">
        <em
          [ngbPopover]="popContent"
          container="body"
          triggers="mouseenter:mouseleave"
          class="fa fa-exclamation-circle pl-2 text-danger"
        ></em>
        <ng-template
          #popContent
          i18n="@@settings.text-only-office-admin-can-edit"
        >
          Only an Office Admin can edit this section.
        </ng-template>
      </div>

      <div class="flex" *ngIf="isEditingOfficeInformation">
        <button
          class="office-settings-icon-button save"
          mat-icon-button
          aria-label="Save office name"
          class="office-settings-icon-button save"
          (click)="saveOfficeChanges()"
        >
          <mat-icon>done</mat-icon>
        </button>
        <button
          class="office-settings-icon-button discard"
          mat-icon-button
          aria-label="Stop editing"
          (click)="stopEditingOfficeInformation()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </section>
    <section class="body">
      <div class="form-field-group mb-2">
        <mat-form-field appearance="outline" ngDefaultControl>
          <mat-label i18n="@@office-setting.iata">IATA</mat-label>
          <input
            matInput
            type="text"
            class="input-field"
            aria-label="IATA"
            formControlName="iata"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" ngDefaultControl>
          <mat-label i18n="@@office-setting.cass">CASS</mat-label>
          <input
            matInput
            type="text"
            class="input-field"
            aria-label="CASS"
            formControlName="cass"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" ngDefaultControl>
          <mat-label i18n="@@office-setting.country">Country</mat-label>
          <input
            matInput
            type="text"
            class="input-field"
            aria-label="Country"
            formControlName="country"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" ngDefaultControl>
          <mat-label i18n="@@office-setting.airport">Airport</mat-label>
          <input
            matInput
            type="text"
            class="input-field"
            aria-label="Airport"
            formControlName="airport"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" ngDefaultControl>
          <mat-label i18n="@@office-setting.company">Company</mat-label>
          <input
            matInput
            type="text"
            class="input-field"
            aria-label="Company"
            formControlName="company"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-checkbox formControlName="shareQuotes">
          <div style="display: flex">
            <p style="margin-bottom: 0" i18n="@@office-setting.share-quotes">
              Share quotes and bookings across office
            </p>
          </div>
        </mat-checkbox>
        <img
          class="protag-img"
          src="/assets/media/icons/pro-tag-share-quote.svg"
          (click)="showUpgradeMessage()"
          alt=""
        />
      </div>
    </section>
  </article>
</form>
<div
  class="plan-section office-settings-form"
  [formGroup]="officeInformationForm"
  id="plan-information-form"
  *ngIf="isCargoMart && officeInformationForm && isForwarderOrStandardUser"
>
  <div class="header">
    <h1 i18n="@@office-setting.plan">PLAN</h1>
  </div>
  <div class="plan-content">
    <mat-form-field appearance="outline" ngDefaultControl>
      <input
        matInput
        type="text"
        class="input-field"
        aria-label="Plan"
        formControlName="plan"
      />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      ngDefaultControl
      *ngIf="isAddOnsAvailable"
      style="flex: 1 1 auto"
    >
      <mat-label i18n="@@office-setting.add-ons">Add-ons</mat-label>
      <input
        matInput
        type="text"
        class="input-field"
        aria-label="Add-ons"
        formControlName="addOns"
      />
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      ngDefaultControl
      *ngIf="!isPlanFree"
      style="width: 150px"
    >
      <mat-label i18n="@@office-setting.company">Expiration Date</mat-label>
      <input
        matInput
        type="text"
        class="input-field"
        aria-label="Expiration Date"
        formControlName="planExpiryDate"
      />
    </mat-form-field>
    <button
      mat-flat-button
      color="primary"
      *ngIf="!isPlanPro && isCargoMartProEnabled"
      (click)="onViewPlansClick()"
    >
      <span i18n="@@office-setting.view-plans">VIEW PLANS</span>
    </button>
  </div>
</div>
<div class="d-flex align-items-end" style="gap: 32px">
  <div>
    <h2 *ngIf="isCargoWallet">{{ officeNameToUpdate }}</h2>
    <div id="office-users-table-controls" class="form-field-group">
      <mat-form-field appearance="outline" class="office-settings-form-field">
        <input
          matInput
          type="text"
          class="input-field"
          aria-label="Search for user"
          placeholder="SEARCH FOR USER"
          i18n-placeholder="@@office-setting.search-for-user"
          [ngModel]="search"
          (ngModelChange)="onSearch($event)"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <button
        mat-flat-button
        *ngIf="iAmAdmin"
        color="primary"
        (click)="onInviteUserButtonClick()"
        class="office-settings-action-button"
      >
        <mat-icon>add_circle</mat-icon>
        <span i18n="@@office-setting.invite-user">ADD USER</span>
      </button>
    </div>
  </div>

  <section
    *ngIf="isCargoWallet && showPaymentApproval"
    class="payment-approval-section"
  >
    <form
      id="office-payment-approval-form"
      class="office-settings-form"
      *ngIf="officePaymentApprovalForm"
      [formGroup]="officePaymentApprovalForm"
    >
      <section
        class="d-flex align-items-end"
        style="gap: 8px; margin-bottom: 24px; min-height: 24px"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10.0001" cy="10.0001" r="7.64708" fill="#53A8E7" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 3.25C6.27209 3.25 3.25 6.27209 3.25 10C3.25 13.728 6.27209 16.75 10 16.75C13.728 16.75 16.75 13.728 16.75 10C16.75 6.27209 13.728 3.25 10 3.25ZM1.75 10C1.75 5.44366 5.44366 1.75 10 1.75C14.5564 1.75 18.25 5.44366 18.25 10C18.25 14.5564 14.5564 18.25 10 18.25C5.44366 18.25 1.75 14.5564 1.75 10ZM10 8.41669C10.4142 8.41669 10.75 8.75247 10.75 9.16669L10.75 13.3334C10.75 13.7476 10.4142 14.0834 10 14.0834C9.58581 14.0834 9.25002 13.7476 9.25002 13.3334L9.25002 9.16669C9.25002 8.75247 9.58581 8.41669 10 8.41669ZM10 7.50002C10.4603 7.50002 10.8334 7.12692 10.8334 6.66668C10.8334 6.20644 10.4603 5.83334 10 5.83334C9.53978 5.83334 9.16669 6.20644 9.16669 6.66668C9.16669 7.12692 9.53978 7.50002 10 7.50002Z"
            fill="white"
          />
        </svg>
        <h3 i18n="@@office-setting.payment-approval-limit">
          Payment Approval Limit
        </h3>
        <mat-form-field
          ngDefaultControl
          *ngIf="showPaymentApprovalThreshold"
          class="mr-4"
        >
          <input
            matInput
            type="text"
            class="input-field"
            aria-label="threshold"
            formControlName="approvalThreshold"
          />
          <span matTextPrefix>$&nbsp;</span>
          <span matTextSuffix>USD</span>
        </mat-form-field>
        <h3 *ngIf="!showPaymentApprovalThreshold">
          <span i18n="@@global.inactive">Inactive</span>
          <em
            *ngIf="iAmAdmin && !isWalletActive"
            class="fa fa-exclamation-circle ml-2"
            style="font-size: 16px"
            matTooltipPosition="below"
            matTooltip="The wallet of this office is not activated. Payment Approval can only be set for activated wallets."
          >
          </em>
        </h3>

        <section class="errors">
          <p
            *ngIf="approvalControlHasError('approvalThreshold', 'required')"
            i18n="@@office-setting.threshold-required"
          >
            Approval Threshold is required
          </p>
          <p
            *ngIf="approvalControlHasError('approvalThreshold', 'isNumber')"
            i18n="@@office-setting.threshold-must-be-number"
          >
            Approval Threshold must be a number
          </p>
        </section>
      </section>
      <section
        class="d-flex"
        style="gap: 8px"
        [class.invisible]="paymentApprovalDisabled"
      >
        <mat-radio-group formControlName="isActive">
          <mat-radio-button
            class="office-settings-radio-button"
            [value]="false"
          >
            I don't want to set a Payment Approval
          </mat-radio-button>
          <mat-radio-button class="office-settings-radio-button" [value]="true">
            I want to set a Payment Approval
          </mat-radio-button>
        </mat-radio-group>

        <button
          mat-flat-button
          color="primary"
          i18n="@@office-setting.save"
          aria-label="Save payment approval"
          [disabled]="!officePaymentApprovalForm?.valid"
          (click)="savePaymentApprovalChanges()"
          class="office-settings-action-button"
        >
          SAVE
        </button>
      </section>
    </form>
  </section>
</div>
<article id="office-users">
  <table
    id="office-users-table"
    class="table table-hover table-checkable sortable table-responsive office-settings-table"
    matSort
    [matSortActive]="sortState.active"
    [matSortDirection]="sortState.direction"
    (matSortChange)="sortData($event)"
  >
    <thead>
      <tr>
        <th
          id="col-name"
          scope="col"
          mat-sort-header="firstName"
          i18n="@@office-setting.name"
        >
          Name
        </th>
        <th
          id="col-email"
          scope="col"
          mat-sort-header="email"
          i18n="@@office-setting.email"
        >
          Email
        </th>
        <th id="col-roles" scope="col" i18n="@@office-setting.roles">Roles</th>
        <th
          id="col-actions"
          scope="col"
          i18n="@@office-setting.actions"
          *ngIf="iAmAdmin"
        >
          Actions
        </th>
        <th
          id="col-permissions"
          scope="col"
          i18n="@@office-setting.permissions"
        >
          Permissions
        </th>
        <th id="col-fill"></th>
      </tr>
    </thead>
    <tbody *ngIf="!isLoading">
      <tr
        *ngFor="
          let user of activeOfficeUsers | paginate: pagination;
          trackBy: trackById
        "
        [ngStyle]="{
          background: user.userId === currentUser.userId ? '#F7F7FB' : null,
        }"
      >
        <td headers="col-name">{{ user.firstName }} {{ user.lastName }}</td>
        <td headers="col-email">{{ user.userEmail }}</td>
        <td headers="col-roles">{{ getRoles(user) }}</td>
        <td headers="col-actions" *ngIf="iAmAdmin">
          <div class="member-actions" *ngIf="!isAdmin(user)">
            <button
              class="office-settings-icon-button"
              mat-icon-button
              color="primary"
              aria-label="Edit office user"
              (click)="onEditUserButtonClick(user)"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              class="office-settings-icon-button"
              mat-icon-button
              color="primary"
              aria-label="Delete office user"
              (click)="onRemoveUserButtonClick(user)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </td>
        <td headers="col-permissions">
          <em
            class="fa fa-exclamation-circle"
            style="font-size: 16px"
            [ngbPopover]="permissionsTooltip"
            placement="right auto"
            triggers="mouseenter:mouseleave"
            popoverClass="permissions-tooltip office-settings-tooltip"
            (mouseenter)="hoveredUser = user"
          >
          </em>
        </td>
        <td headers="col-fill"></td>
      </tr>
    </tbody>
  </table>
  <kt-loading-component [isLoading]="isLoading"></kt-loading-component>
  <div id="office-users-table-pagination" *ngIf="!isLoading">
    <pagination-controls
      [previousLabel]="PREVIOUS_LABEL"
      [nextLabel]="NEXT_LABEL"
      (pageChange)="onPageChanged($event)"
    ></pagination-controls>
  </div>
</article>
<div class="wallet-privacy-container">
  <div class="wallet-privacy-header" *ngIf="isWalletActive && isCargoWallet">
    <h3>Wallet Privacy</h3>
    <img
      src="/assets/media/icons/wallet/toolTip.svg"
      class="icon-default"
      alt=""
      matTooltip="Only an Office Admin can edit wallet privacy settings"
      matTooltipPosition="above"
    />
  </div>
  <div
    class="wallet-privacy-options"
    *ngIf="!isAdmin(currentUser) && isWalletActive && isCargoWallet"
  >
    <span class="wallet-privacy-label">{{ WalletPrivacy }}</span>
  </div>
  <div
    class="wallet-privacy-options"
    *ngIf="isAdmin(currentUser) && isWalletActive && isCargoWallet"
  >
    <form
      id="office-information-form"
      class="office-settings-form"
      [formGroup]="walletPrivacyForm"
    >
      <mat-radio-group
        formControlName="walletPrivacy"
        (change)="onRadioChange($event)"
      >
        <mat-radio-button class="wallet-privacy-radio-button" [value]="true">
          <span class="radio-label">Public</span>
          <span class="radio-desc"
            >Make this Wallet visible so I can receive payment</span
          >
        </mat-radio-button>
        <mat-radio-button class="wallet-privacy-radio-button" [value]="false">
          <span class="radio-label">Private</span>
          <span class="radio-desc"
            >Make this Wallet invisible, so that it doesn't appear to other
            users. I won't be able to receive any payment.</span
          >
        </mat-radio-button>
      </mat-radio-group>
      <button
        mat-flat-button
        color="primary"
        i18n="@@office-setting.save"
        aria-label="Save payment approval"
        (click)="onSave()"
        class="office-settings-save-button"
        [disabled]="!isNewSelection"
        [ngClass]="{ 'new-radio': isNewSelection }"
      >
        SAVE
      </button>
    </form>
  </div>

  <kt-splash-screen *ngIf="isPageLoading"></kt-splash-screen>
  <ng-template #permissionsTooltip>
    <strong class="d-block mb-2">Permissions</strong>
    <div *ngFor="let permission of getPermissionsOfHoveredUser">
      <mat-checkbox [checked]="true" disabled></mat-checkbox>
      <mat-label>{{ permission }}</mat-label>
    </div>
  </ng-template>
</div>
