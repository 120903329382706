import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CommodityType, IRequirementAnswer, RequirementCodeEnum } from "@cai-services";
import { CoolingPackagingConfig } from "../../../constant/requirements/cooling-packaging.const";
import { TemperatureFullAnswer } from "../../../constant/requirements/temperature-full.const";
import { SessionService } from "../../../core/_services/session.service";

const PARENT_COMPONENT = RequirementCodeEnum.TEMPERATURE_FULL;

@Component({
  "selector": "kt-requirement-cooling-packaging",
  "templateUrl": "./requirement-cooling-packaging.component.html",
  "styleUrls": ["./requirement-cooling-packaging.component.scss"],
})
export class RequirementCoolingPackagingComponent implements OnInit {
  @Output() onFocus = new EventEmitter();

  formGroup: FormGroup = new FormGroup({});
  code = "";
  defaultAnswer = {} as IRequirementAnswer;
  selectedAnswer: any = {};
  question: string = CoolingPackagingConfig.question;
  options: any[] = CoolingPackagingConfig.options;
  visible = false;

  constructor (
    private readonly cdr: ChangeDetectorRef,
    private sessionService: SessionService,
  ) {}

  ngOnInit (): void {
    this.updateAnswer(this.formGroup.get(this.code).value);
    this.formGroup.get(this.code).valueChanges.subscribe((value) => {
      this.updateAnswer(value);
    });

    const initialValue = this.formGroup.get(PARENT_COMPONENT).value;
    this.updateAnswerBasedOnParent(initialValue);
    this.formGroup.get(PARENT_COMPONENT).valueChanges.subscribe((value) => {
      this.updateAnswerBasedOnParent(value);
    });
    this.cdr.detectChanges();
  }

  selectAnswer (option) {
    if (this.selectedAnswer !== option) {
      this.selectedAnswer = option;
      this.formGroup.get(this.code).setValue(option.value);
      this.onFocus.emit();
    }
  }

  private updateAnswerBasedOnParent (value) {
    const goodsType = this.sessionService.getSelectedGoodsType() as any
    const shouldAlwaysDisplay = goodsType?.name == CommodityType.PERISHABLE || goodsType?.name == CommodityType.PHARMACEUTICALS 
    const visible = value === TemperatureFullAnswer.FROZEN,
     field = this.formGroup.get(this.code);
    if (visible || shouldAlwaysDisplay) {
      this.visible = true;
      if (field.value) {
        this.updateAnswer(field.value);
      } else {
        field.setValue(this.defaultAnswer.code);
      }
    } else {
      this.visible = false;
      field.setValue(null);
    }
  }

  private updateAnswer (value) {
    const selected = this.options.find((opt) => opt.value === value);
    if (selected) {
      this.selectedAnswer = selected;
      this.cdr.detectChanges();
    }
  }
}
