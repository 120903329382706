import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { RateService } from '@cai-services';
import moment from 'moment';
import { AircraftColorPipe } from '../../core/_base/layout/pipes/aircraft-color.pipe';
import { TimeDiffer } from '../../core/_base/layout/pipes/time-diff.pipe';
import { TimeFromStartDifferPipe } from '../../core/_base/layout/pipes/time-start-diff.pipe';
import { Aircraft } from '../../core/_models/aircraft.model';
import { AirlineBrand } from '../../core/_models/airline.model';
import {
  CustomFlightDetails,
  FlightInput,
} from '../../core/_models/flight-input.model';
import { UserMeasurementSystem } from '../../core/_models/measurement-system.model';
import { SearchTemplate } from '../../core/_models/search-template.model';
import { FlightRate } from '../../core';

@Component({
  selector: 'kt-smart-view-table',
  templateUrl: './smart-view-table.component.html',
  styleUrls: ['./smart-view-table.component.scss'],
  providers: [TimeDiffer, TimeFromStartDifferPipe, AircraftColorPipe],
})
export class SmartViewTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchUUID: string;
  @Input() weight: number;
  @Input() isSelectAll: boolean;
  @Input() chargeableWeight: number;
  @Input() measurementUnit: UserMeasurementSystem;
  @Input() allFlights: CustomFlightDetails[] = [];
  @Input() sliderFirstDep: Date;
  @Input() sliderLastArr: Date;
  @Input() flightDays: number;
  @Input() isShipperQuote: boolean;
  @Input() selectedFlightDetails: FlightInput[] = [];
  @Input() isBatchLoading: boolean;
  @Input() isEnableQuoteRequest: boolean;
  @Input() activeColumn: number;
  @Input() aircraftList: Aircraft[] = [];
  @Input() searchTemplate: SearchTemplate;
  @Input() selectedFlight: CustomFlightDetails;
  @Input() flightIndex: number;
  @Input() popover: boolean;
  @Input() isNewSearch: boolean;
  @Input() isShipmentDetailsPage: boolean;
  @Input() isExpressOnly: boolean;

  @Input() qualityScoreList: { [key: string]: string }[] = [];

  @Output() flightSelected = new EventEmitter<CustomFlightDetails>();
  @Output() onSelectSingleFlight = new EventEmitter<{
    payload: { checked: boolean; sortKey: string };
    airlineCompany: AirlineBrand;
  }>();
  @Output() onSelectAll = new EventEmitter<{
    checked: boolean;
    payload: string[];
  }>();
  @Output() onFlightBook = new EventEmitter<{
    payload: any;
  }>();
  @Output() onRequestBook = new EventEmitter<{
    payload: any;
  }>();
  @Output() onRateChange = new EventEmitter<{
    sortKey: string;
    selectedRate: FlightRate;
  }>();
  @Output() onMoveFlightDaysView = new EventEmitter();

  flightsByAirline: {
    airlineCompany: AirlineBrand;
    flights: CustomFlightDetails[];
  }[] = [];
  airlinesWithRateInsight: string[] = [];
  isLoading = true;
  expandFlightState: { [key: string]: boolean } = {};
  airlinesToShow = 5;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly rateService: RateService,
  ) {}

  ngOnInit() {
    this.getFlightByAirline();
    if (!this.isNewSearch) {
      this.getCoverage();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.allFlights?.currentValue) {
      this.getFlightByAirline();
    }
  }

  showMoreAirlines() {
    this.airlinesToShow += 100;
  }

  updateExpandFlightState(event: { key: string; value: boolean }) {
    this.expandFlightState[event.key] = event.value;
    this.cdr.markForCheck();
  }

  getFlightByAirline(): void {
    this.flightsByAirline = this.allFlights.reduce((result, flight) => {
      let flightGroup = result.find(
        (f) =>
          f.airlineCompany.airlineCompanyCode ===
          flight.airlineCompany.airlineCompanyCode,
      );
      if (!flightGroup) {
        flightGroup = { airlineCompany: flight.airlineCompany, flights: [] };
        result.push(flightGroup);
      }
      if (
        !flightGroup.flights.some(
          (f) => f.sortingFlightKey === flight.sortingFlightKey,
        )
      ) {
        flightGroup.flights.push(flight);
      }
      return result;
    }, []);
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  isExpress(): boolean {
    return this.searchTemplate?.requirements?.some((req) =>
      req?.answer?.specialHandlingCodes?.some((shc) => shc?.shcCode === 'XPS'),
    );
  }

  getProductCode(): string {
    return this.isExpress()
      ? 'XPS'
      : this.searchTemplate?.typeOfProduct?.productCode || '';
  }

  async getCoverage() {
    const officeId =
      this.allFlights
        ?.flatMap((flight) => flight.rates || [])
        .find((rate) => rate?.coloaderOfficeId)?.coloaderOfficeId ||
      this.searchTemplate?.office?.id;

    const departureDate = moment(this.searchTemplate?.searchDate).format(
      'YYYY-MM-DD',
    );
    this.airlinesWithRateInsight = await this.rateService.getRateCoverage(
      this.searchTemplate?.origin,
      this.searchTemplate?.destination,
      this.getProductCode(),
      officeId,
      departureDate,
    );
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  onToggleAllFlight(checked: boolean) {
    const flights = checked
      ? this.flightsByAirline
          .map((item) => item.flights[0]?.sortingFlightKey)
          .filter(Boolean)
      : [];
    this.onSelectAll.emit({ checked, payload: flights });
    this.isSelectAll = checked;
    this.cdr.markForCheck();
  }

  onSelectFlight(event: any, airlineCompany: AirlineBrand): void {
    this.onSelectSingleFlight.emit({ payload: event, airlineCompany });
  }

  onBookClicked(event: any): void {
    this.onFlightBook.emit(event);
  }

  onRequestBookClicked(event: any): void {
    this.onRequestBook.emit(event);
  }

  onRateChangeEvent(event: any): void {
    this.onRateChange.emit(event);
  }

  getQualityScore(airline: AirlineBrand): number {
    return this.getQualityMetric(airline, 'qualityScore');
  }

  getAvgQualityScore(airline: AirlineBrand): number {
    return this.getQualityMetric(airline, 'avgQualityScoreByAirline');
  }

  private getQualityMetric(airline: AirlineBrand, metricKey: string): number {
    const metric = this.qualityScoreList?.find((score) =>
      score.airlineCode.includes(airline.airlineCompanyCode),
    );
    return +(+metric?.[metricKey] * 100).toFixed(2) || undefined;
  }
}
