import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EmbeddedViewRef,
  Input,
  NgZone,
  OnChanges,
  Optional,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import _ from 'lodash';
import Popper from 'popper.js';
import { filter, fromEvent, takeWhile } from 'rxjs';
import { DropdownOption } from '../../core/_models/dropdown-option.model';

@Component({
  selector: 'common-advanced-multiselect',
  templateUrl: './advanced-multiselect.component.html',
  styleUrls: ['./advanced-multiselect.component.scss'],
})
export class AdvancedMultiselectComponent
  implements OnChanges, ControlValueAccessor
{
  @ViewChild('input') input: ElementRef;
  @Input() label: string;
  @Input() defaultMsg = 'No result found';
  @Input() textCenter: boolean;
  @Input() uppercase: boolean;
  @Input() floatLabel: boolean;
  @Input() minWidth = 280;
  @Input() maxWidth = 320;
  @Input() inputHeight = 38;
  @Input() isEawb: boolean;
  @Input() isDisabled: boolean;

  private _options: DropdownOption[] = [];
  get options() {
    return this._options;
  }
  @Input() set options(e: DropdownOption[]) {
    this._options = _.cloneDeep(e);
  }

  private _value: string[] = [];
  get value() {
    return this._value;
  }
  set value(e: string[]) {
    this.findOption(e);
    const value = this.selected?.map((item) => item?.value);
    this.onChange(value);
    this._value = value;
    this.cdr.detectChanges();
  }

  display = '';
  searchKey: string;
  filteredOptions: DropdownOption[] = [];
  popperRef: Popper;
  view: EmbeddedViewRef<any>;
  selected: DropdownOption[];

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(
    @Self()
    @Optional()
    private readonly control: NgControl,
    private readonly zone: NgZone,
    private readonly vcr: ViewContainerRef,
    private readonly cdr: ChangeDetectorRef,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('options')) {
      this.findOption(this.value);
      this.cdr.detectChanges();
    }
  }

  toggleSelection(option: DropdownOption) {
    const selected = this.selected?.find((i) => i.value === option.value);
    if (selected) {
      const index = this.selected?.findIndex(
        (i) => i.value === selected?.value,
      );
      this.selected?.splice(index, 1);
    } else {
      this.selected?.push(option);
    }
    this.value = this.selected
      ?.map((item) => item?.value)
      .sort((a, b) => a?.localeCompare(b));
    this.display = this.selected
      .map((item) => item?.display || item?.label)
      .sort((a, b) => a?.localeCompare(b))
      .join(', ');
    this.cdr.detectChanges();
  }

  open(template: TemplateRef<any>, wrapper: HTMLElement) {
    if (this.isOpen) {
      this.close();
    }
    if (this.options.length) {
      const selected = this.options
          .filter((option) => this.value.includes(option.value))
          .sort((a, b) => a?.label?.localeCompare(b.label)),
        unselected = this.options
          .filter((option) => !this.value.includes(option.value))
          .sort((a, b) => a?.label?.localeCompare(b.label));
      this.options = [...selected, ...unselected];
      this.view = this.vcr.createEmbeddedView(template);
      const dropdown = this.view.rootNodes[0];
      document.body.appendChild(dropdown);

      this.zone.runOutsideAngular(() => {
        this.popperRef = new Popper(wrapper, dropdown, {
          placement: 'bottom-start',
          modifiers: {
            offset: {
              enabled: true,
              offset: '0, 10',
            },
            keepTogether: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: true,
            },
          },
        });
      });

      fromEvent(document, 'click')
        .pipe(
          takeWhile(() => this.isOpen),
          filter(({ target }) => {
            const origin = this.popperRef?.reference as HTMLElement,
              popup = document.getElementsByClassName(
                'dropdown--multiselect',
              )[0],
              el = target as HTMLElement;
            return !origin?.contains(el) && !popup?.contains(el);
          }),
        )
        .subscribe(() => {
          this.close();
        });
    }
  }

  close() {
    this.popperRef?.destroy();
    this.view?.destroy();
    this.popperRef = null;
    this.view = null;
    this.searchKey = '';
    this.cdr.detectChanges();
  }

  handleKeywordChange(keyword) {
    this.searchKey = keyword;
    if (keyword) {
      this.filteredOptions = this.findMatches(keyword, this.options);
    } else {
      this.filteredOptions = [];
    }
    this.cdr.detectChanges();
  }

  findMatches(keyword: string, options: DropdownOption[]) {
    return options.filter((option) => {
      const defaultMatch: boolean = this.convertCase(option.label).startsWith(
        this.convertCase(keyword),
      );
      let criteriaMatch: boolean;
      if (option?.criteria?.length) {
        criteriaMatch =
          option?.criteria.filter((item) =>
            this.convertCase(item.value, item.textCase).startsWith(
              this.convertCase(keyword, item.textCase),
            ),
          ).length > 0;
      }
      return criteriaMatch ? criteriaMatch : defaultMatch;
    });
  }

  convertCase(
    text: string,
    textCase: 'lowercase' | 'uppercase' = 'lowercase',
  ): string {
    if (text) {
      return textCase === 'uppercase' ? text.toUpperCase() : text.toLowerCase();
    }
    return text;
  }

  findOption(value: string[]) {
    const selected = this.options?.filter((item) =>
      value?.includes(item.value),
    );
    this.selected = selected?.map((item) => ({
      ...item,
      checked: true,
    }));
    this.display = this.selected
      ?.map((item) => item?.display || item?.label)
      .join(', ');
    this.cdr.detectChanges();
  }

  writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {}

  get isOpen(): boolean {
    return !!this.popperRef;
  }

  get height(): number {
    return (this.searchKey ? this.filteredOptions : this.options).length * 34;
  }

  get showError(): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  get invalid(): boolean {
    return this.control?.touched ? this.control.invalid : false;
  }

  get isFocused(): boolean {
    return this.input?.nativeElement === document.activeElement;
  }
}
