import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  OnChanges,
} from '@angular/core';
import { CaiPopoverDirective } from '@cai-framework';
import { MeasurementSystemEnum } from '@cai-services';
import moment from 'moment';
import { AircraftColorPipe } from '../../core/_base/layout/pipes/aircraft-color.pipe';
import { TimeDiffer } from '../../core/_base/layout/pipes/time-diff.pipe';
import { TimeFormatPipe } from '../../core/_base/layout/pipes/time-format.pipe';
import { TimeFromStartDifferPipe } from '../../core/_base/layout/pipes/time-start-diff.pipe';
import { DATE_FORMAT_WITH_TIMEZONE } from '../../core/_constants/constants';
import { Aircraft } from '../../core/_models/aircraft.model';
import {
  CustomFlightDetails,
  FlightInput,
  Leg,
} from '../../core/_models/flight-input.model';
import { UserMeasurementSystem } from '../../core/_models/measurement-system.model';
import { SearchTemplate } from '../../core/_models/search-template.model';
import { SessionService } from '../../core/_services/session.service';
import {
  formatStringDateTime,
  getDuration,
  getStringDate,
} from '../../helpers/date-helpers';
import { getDefaultAircraft } from '../../helpers/search_helpers';
import { FlightRate } from '../../core';
import { DateTimeUtil } from '../../utils';

@Component({
  selector: 'kt-flight-paths',
  templateUrl: './flight-paths.component.html',
  styleUrls: ['./flight-paths.component.scss'],
  providers: [
    TimeDiffer,
    TimeFromStartDifferPipe,
    AircraftColorPipe,
    TimeFormatPipe,
  ],
})
export class FlightPathsComponent implements OnInit, OnChanges {
  @ViewChildren(CaiPopoverDirective) popups: QueryList<CaiPopoverDirective>;
  @Input() dayDiff: number;
  @Input() flightPaths: ComputedLeg[] = [];
  @Input() isColumnActive: boolean;
  @Input() aircraftList: Aircraft[] = [];
  @Input() lastDay: Date;
  @Input() firstDay: Date;
  @Input() disableTooltip: boolean;
  @Input() isEnableQuoteRequest: boolean;
  @Input() measurementUnit: UserMeasurementSystem;
  @Input() searchTemplate: SearchTemplate;
  @Input() flightDetails: any;
  @Input() popover: boolean;
  @Input() isQuoteCard: boolean;
  @Input() isQuoteCartPage: boolean;
  @Output() onBookClicked = new EventEmitter<{ payload: FlightInput }>();
  @Output() onRequestBookClicked = new EventEmitter<{
    payload: FlightInput;
  }>();
  @Output() onSelectRate = new EventEmitter<{
    flight: CustomFlightDetails;
    rate: FlightRate;
  }>();

  userMeasurementSystem: MeasurementSystemEnum;
  readonly minOptionDate: Date;

  constructor(
    public timeDifferPipe: TimeDiffer,
    public timeFromStartDiffer: TimeFromStartDifferPipe,
    public timeFormatPipe: TimeFormatPipe,
    private readonly sessionService: SessionService,
  ) {
    this.minOptionDate = DateTimeUtil.businessDaysFrom(new Date(), 4);
  }

  ngOnInit(): void {
    this.computeFlightPaths();
    this.userMeasurementSystem =
      this.sessionService.getCurrentUser()?.measurementSystem;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('dayDiff') ||
      changes.hasOwnProperty('flightPaths')
    ) {
      this.computeFlightPaths();
    }
  }

  getFlightDuration(departureTime, arrivalTime) {
    return getDuration(departureTime, arrivalTime);
  }

  get CO2(): string {
    return this.flightPaths
      .filter((path) => path?.co2)
      .map((path) => Math.ceil(path?.co2?.value ?? 0))
      .reduce((sum, current) => sum + current, 0)
      .toFixed();
  }

  get isCO2Estimation(): boolean {
    return this.flightPaths.some((path) => path?.co2?.estimation);
  }

  get isArrivingLastDay(): boolean {
    if (this.flightPaths?.length === 0) {
      return false;
    }
    const arrivalTime = getStringDate(
      this.flightPaths?.[this.flightPaths?.length - 1]?.arrivalTime,
    );
    const lastDay = getStringDate(this.lastDay);
    if (!arrivalTime || !lastDay) {
      return false;
    }
    return moment(arrivalTime).isSameOrAfter(lastDay);
  }

  getBeforePath(beforePath) {
    if (this.popover && parseFloat(beforePath) < 23.5) {
      return '23.5';
    }
    return beforePath;
  }

  computeFlightPaths(): void {
    let totalPathLength = 0;
    this.flightPaths.forEach((path, index) => {
      const isSecondPartition =
        this.parseDate(this.flightPaths?.[0].departureTime) <
        this.parseDate(this.firstDay);
      if (index !== this.flightPaths.length - 1) {
        const startDate =
          this.parseDate(path.arrivalTime) < this.parseDate(this.firstDay) &&
          this.parseDate(this.flightPaths[index + 1].departureTime) >=
            this.parseDate(this.firstDay)
            ? this.parseDateTime(
                this.firstDay.toISOString(),
                DATE_FORMAT_WITH_TIMEZONE,
              )
            : this.parseDateTime(path.arrivalTime, DATE_FORMAT_WITH_TIMEZONE);

        path.transitTime = this.timeDifferPipe.transform(
          startDate,
          this.parseDateTime(
            this.flightPaths[index + 1].departureTime,
            DATE_FORMAT_WITH_TIMEZONE,
          ),
          this.dayDiff,
        );

        const mainPathStartDate =
          (isSecondPartition &&
            this.parseDate(path.departureTime) <
              this.parseDate(this.firstDay) &&
            this.parseDate(path.arrivalTime) > this.parseDate(this.firstDay)) ||
          (isSecondPartition && index === 0)
            ? this.parseDateTime(
                this.firstDay.toISOString(),
                DATE_FORMAT_WITH_TIMEZONE,
              )
            : this.parseDateTime(path.departureTime, DATE_FORMAT_WITH_TIMEZONE);

        path.mainPath = this.timeDifferPipe.transform(
          mainPathStartDate,
          this.parseDateTime(path.arrivalTime, DATE_FORMAT_WITH_TIMEZONE),
          this.dayDiff,
        );

        totalPathLength += path.mainPath;
        if (
          this.parseDate(path.arrivalTime) < this.parseDate(this.firstDay) &&
          path.mainPath < 0
        ) {
          path.mainPath = 0;
        }
      } else {
        const untilEnd = this.timeDifferPipe.getTimeUntilEnd(
          path.arrivalTime,
          this.dayDiff,
        );
        path.untilEnd = untilEnd;
        if (path.untilEnd < 8) {
          path.lastPosition = 100;
        } else if (path.untilEnd < 15) {
          path.lastPosition = 90;
        }

        let totalTransitTime = 0;
        this.flightPaths.forEach((flightPath, flightIndex) => {
          if (flightIndex !== this.flightPaths.length - 1) {
            totalTransitTime += this.timeDifferPipe.transform(
              flightPath.arrivalTime,
              this.flightPaths[flightIndex + 1].departureTime,
              this.dayDiff,
            );
          }
        });

        path.mainPath =
          100 -
          (isSecondPartition ? 0 : this.beforePath) -
          totalPathLength -
          totalTransitTime -
          (this.parseDate(path.arrivalTime) > this.parseDate(this.lastDay)
            ? 0
            : untilEnd);

        if (
          this.parseDate(path.arrivalTime) > this.parseDate(this.lastDay) &&
          this.flightPaths?.length > 1 &&
          path.mainPath < 0
        ) {
          path.mainPath = 0;
        }
      }
    });
  }

  parseDate(date: string | Date): Date {
    if (date) {
      return new Date(getStringDate(date, 'MMM DD, YYYY'));
    }
    return null;
  }

  parseDateTime(date: string, format: string): Date {
    if (date) {
      return new Date(formatStringDateTime(date, format));
    }
    return null;
  }

  updatePopups() {
    this.popups.toArray().forEach((popup) => {
      if (popup.popperRef) {
        popup.popperRef.update();
      }
    });
  }

  isLegsLight(path: Leg): boolean {
    const aircraft = this.getAirCraft(path);
    if (aircraft) {
      return aircraft?.aircraftType?.aircraftTypeName == 'Narrowbody';
    }
    return false;
  }

  getCO2(legs: Leg[]): string {
    return legs
      .filter((path) => path?.co2)
      .map((path) => Math.ceil(path?.co2?.value) || 0)
      .reduce((sum, current) => sum + current, 0)
      .toFixed();
  }

  getMainTooltip(path: Leg): string {
    if (this.disableTooltip) {
      return null;
    }
    const aircraft = this.getAirCraft(path);
    return `${path.flightNumber} - ${aircraft.aircraftName} - ${aircraft.aircraftType.aircraftTypeName}`;
  }

  getTransitTooltip(path: Leg, index: number): string {
    if (this.disableTooltip) {
      return null;
    }
    return `${path.arrivalAirport} ${this.timeFormatPipe.transform(
      path.arrivalTime,
    )} - ${this.timeFormatPipe.transform(
      this.flightPaths[index + 1].departureTime,
    )}`;
  }

  getAirCraft(path: Leg): Aircraft {
    const foundAircraft = this.aircraftList.find(
      (aircraft) => aircraft.aircraftCode === path.aircraftCode,
    );
    if (foundAircraft) {
      return foundAircraft;
    }
    return getDefaultAircraft();
  }

  get beforePath(): number {
    return this.timeFromStartDiffer.transform(
      this.flightPaths[0].departureTime,
      this.dayDiff,
    );
  }

  get firstDayOfFlight(): Date {
    return this.parseDate(this.flightPaths?.[0].departureTime);
  }
}

interface ComputedLeg extends Leg {
  transitTime?: number;
  mainPath?: number;
  untilEnd?: number;
  lastPosition?: number;
}
