<!--begin::Form-->
<form class="form personal-settings-form" (ngSubmit)="onSubmit()">
  <div class="row wrapper">
    <div class="col-lg-12">
      <div class="mb-2">
        <h2 i18n="@@profile.notificaion-preferences">
          Notification Preferences
        </h2>
      </div>

      <!-- <div class="message">
        We will never distribute your email address to third parties. Read about email communication in our privacy policy.
      </div> -->
      <figure>
        <figcaption></figcaption>
        <table aria-describedby="notification-settings">
          <tbody>
            <tr>
              <td class="not-selectable">Select All</td>
              <td>
                <cai-checkbox
                  [isActive]="allSend.isSendEmail"
                  [styles]="{ 'justify-content': 'center' }"
                  [disabled]="!isFetched"
                  (change)="updateSetting(null, 'isSendEmail', $event)"
                >
                </cai-checkbox>
              </td>
              <td>
                <cai-checkbox
                  [isActive]="allSend.isSendToast"
                  [styles]="{ 'justify-content': 'center' }"
                  [disabled]="!isFetched"
                  (change)="updateSetting(null, 'isSendToast', $event)"
                >
                </cai-checkbox>
              </td>
            </tr>
            <ng-container
              *ngFor="let notification of notificationSettings; let i = index"
            >
              <tr class="not-selectable">
                <th scope="col" [attr.colspan]="i > 0 ? 3 : 1">
                  {{ notification.category }}
                  <span
                    *ngIf="notification.id === 3"
                    caiPopover
                    [enablePopover]="true"
                    [target]="idTooltip"
                    [allowHoverOnTarget]="true"
                    [placement]="'right-start'"
                  >
                    <svg
                      class="info-icon"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM9 4.91667C9.41421 4.91667 9.75 5.25245 9.75 5.66667V9.83333C9.75 10.2475 9.41421 10.5833 9 10.5833C8.58579 10.5833 8.25 10.2475 8.25 9.83333V5.66667C8.25 5.25245 8.58579 4.91667 9 4.91667ZM9 13.1667C9.46024 13.1667 9.83333 12.7936 9.83333 12.3333C9.83333 11.8731 9.46024 11.5 9 11.5C8.53976 11.5 8.16667 11.8731 8.16667 12.3333C8.16667 12.7936 8.53976 13.1667 9 13.1667Z"
                        fill="#679EC6"
                      />
                    </svg>
                    <ng-template #idTooltip>
                      <kt-popover
                        [arrowColor]="'#679EC6'"
                        [bgColor]="'#679EC6'"
                        [textColor]="'white'"
                        [message]="shipmentUpdatesInfo"
                        placement="right-start"
                      >
                      </kt-popover>
                    </ng-template>
                  </span>
                </th>
                <th scope="col" *ngIf="i == 0" i18n="@@notification.Email">
                  Email
                </th>
                <th scope="col" *ngIf="i == 0" i18n="@@notification.In App">
                  In App
                </th>
              </tr>
              <ng-container *ngFor="let setting of notification.settings">
                <tr>
                  <td class="not-selectable">{{ setting.label }}</td>
                  <td>
                    <cai-checkbox
                      caiPopover
                      [target]="popOver"
                      [enablePopover]="
                        !isFetched || setting.isEmailNotApplicable
                      "
                      [isActive]="setting.isSendEmail"
                      [styles]="{ 'justify-content': 'center' }"
                      [disabled]="!isFetched || setting.isEmailNotApplicable"
                      [showDisableColor]="
                        !isFetched || setting.isEmailNotApplicable
                      "
                      (change)="updateSetting(setting, 'isSendEmail', $event)"
                    >
                    </cai-checkbox>
                  </td>
                  <td>
                    <cai-checkbox
                      caiPopover
                      [target]="popOver"
                      [enablePopover]="
                        !isFetched || setting.isPushNotApplicable
                      "
                      [isActive]="setting.isSendToast"
                      [styles]="{ 'justify-content': 'center' }"
                      [disabled]="!isFetched || setting.isPushNotApplicable"
                      [showDisableColor]="
                        !isFetched || setting.isPushNotApplicable
                      "
                      (change)="updateSetting(setting, 'isSendToast', $event)"
                    >
                    </cai-checkbox>
                  </td>
                  <td class="protag" *ngIf="showProTag(setting.name)">
                    <img
                      src="/assets/media/icons/pro-tag.svg"
                      alt="Pro upgrade tag"
                    />
                  </td>
                  <ng-template #popOver>
                    <kt-popover
                      [message]="popMessage(setting)"
                      [arrowColor]="'#679EC6'"
                      [bgColor]="'#679EC6'"
                      [textColor]="'white'"
                    ></kt-popover>
                  </ng-template>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </figure>

      <button
        type="submit"
        [disabled]="loading || !isSettingsUpdated"
        [ngClass]="{
          'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
            loading,
        }"
        class="btn btn-save mt-1"
        i18n="@@global.update-preferences"
      >
        Update Preferences
      </button>
    </div>
  </div>
</form>
<!--end::Form-->
