import { Buffer } from 'buffer';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from '@cai-services';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { environment } from '../../environments';

@Component({
  selector: 'common-superset-dashboard',
  templateUrl: './superset-dashboard.component.html',
})
export class SupersetDashboardComponent implements OnChanges {
  @Input() dashboardId: string;
  @Input() originCountry: string;
  @Input() chartTitle: string;

  constructor(private readonly userService: UserService) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (
      (changes.hasOwnProperty('dashboardId') ||
        changes.hasOwnProperty('originCountry')) &&
      this.dashboardId
    ) {
      window.Buffer = window.Buffer || Buffer;
      const embed = async () => {
        await embedDashboard({
          id: this.dashboardId,
          supersetDomain: environment?.supersetDomain,
          mountPoint: document.getElementById(
            'my-superset-dashboard',
          ) as HTMLElement,
          fetchGuestToken: () =>
            this.userService.getSupersetToken(
              this.dashboardId,
              this.originCountry,
              this.chartTitle === 'Airline Contacts' ? 'airlineContacts' : '',
            ),
          dashboardUiConfig: {
            // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
            hideChartControls: true,
            hideTab: true,
            hideTitle: true,
          },
          iframeSandboxExtras: [
            'allow-top-navigation',
            'allow-popups-to-escape-sandbox',
          ],
        });
      };

      if (document.getElementById('my-superset-dashboard')) {
        embed();
        const iframe = document.querySelector('iframe') as HTMLIFrameElement;
        if (iframe !== null) {
          iframe.style.width = '100%';
          iframe.style.height = '100vh';
          iframe.style.border = 'none';
        }
      }
    }
  }
}
