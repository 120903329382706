import { CompanySubTypeEnum, CompanyTypeEnum, ICompany } from '@cai-services';
import { AirlineBrand } from './airline.model';
import { User } from './user.model';

export class Company implements ICompany {
  id: number;
  companyCode: string;
  companyName: string;
  companyType: CompanyTypeEnum;
  comments?: string;
  airlineBrands: AirlineBrand[];
  emailDomains: string[];
  providers: string[];
  logoHash: string;
  logoType: string;
  uploadURL: string;
  updateLogo: boolean;
  subType: CompanySubTypeEnum;
  users: User[];

  constructor() {
    this.users = new Array<User>();
  }
}
