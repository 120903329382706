export enum UserFeaturesEnum {
  SEARCH_BOOK = 'SEARCH_BOOK',
  BOOK_MODIFY = 'BOOK_MODIFY',
  SEARCH_BUP = 'SEARCH_BUP',
  ALLOTMENT = 'ALLOTMENT',
  TEMPLATE_HISTORY = 'TEMPLATE_HISTORY',
  SPOT_REQUEST = 'SPOT_REQUEST',
  ADVANCED_BOOKING = 'ADVANCED_BOOKING',
  AWB_EAP_EAW_BOOKING = 'AWB_EAP_EAW_BOOKING',
  AWB_SEND_EAWB = 'AWB_SEND_EAWB',
  AWB_AUTO_AWB = 'AWB_AUTO_AWB',
  SHIPMENT_T_T = 'SHIPMENT_T_T',
  CARGO2ZERO_CO2_TRACKING = 'CARGO2ZERO_CO2_TRACKING',
  CARGO2ZERO_PURCHASE_SAF = 'CARGO2ZERO_PURCHASE_SAF',
  CARGO2ZERO_CO2_REPORTING = 'CARGO2ZERO_CO2_REPORTING',
  USER_NOTIFICATION = 'USER_NOTIFICATION',
  HELP_COMMUNITY_ACCESS = 'HELP_COMMUNITY_ACCESS',
  LIVE_MESSAGES = 'LIVE_MESSAGES',
  INTERCOM = 'INTERCOM',
  INTERLINE = 'INTERLINE',
  OPTIONS = 'OPTIONS',
  QUALITY_SCORE = 'QUALITY_SCORE',
  RATE_MANAGEMENT_ACCESS = 'RATE_MANAGEMENT_ACCESS',
  RATE_MANAGEMENT_UPLOAD = 'RATE_MANAGEMENT_UPLOAD',
  ROLE_PERMISSION_MANAGEMENT = 'ROLE_PERMISSION_MANAGEMENT',
  SELLING_RATES = 'SELLING_RATES',
  RATES_ADMIN = 'RATES_ADMIN',
  CARGO_COPILOT_RATE_INSIGHTS = 'CARGO_COPILOT_RATE_INSIGHTS',
  SHARE_QUOTES_ACROSS_OFFICE = 'SHARE_QUOTES_ACROSS_OFFICE',
  ANALYTICS_FORWARDER = 'ANALYTICS_FORWARDER',
  ANALYTICS_AIRLINE = 'ANALYTICS_AIRLINE',
  CARGOMART_PRO = 'CARGOMART_PRO',
  QUOTATION = 'QUOTATION',
  ANALYTICS_AIRLINE_CONTACT = 'ANALYTICS_AIRLINE_CONTACT',
  SHIPMENT_STATUS = 'SHIPMENT_STATUS',
  LOCAL_CHARGE = 'LOCAL_CHARGE',
}
