import {
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  OnChanges,
  OnInit,
  EventEmitter,
  HostListener,
  Inject,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import intlTelInput from 'intl-tel-input';
import { UserService } from '@cai-services';
import Swal from 'sweetalert2';
import { User } from '../../../../../core/_models/user.model';
import { PhoneFormatterPipe } from '../../../../../core/_base/layout/pipes/phone-formatter.pipe';
import { Contact } from '../../../../../core/_models/contact.model';
import { IntlTelFormatEnum } from '../../../../../core/_enums/intl-tel-format.enum';
import { SessionService } from '../../../../../core/_services/session.service';
import { APP_PROPS } from '../../../../../cai-common.module';
import { ApplicationProperties } from './../../../../../core';

@Component({
  selector: 'kt-about-personal-settings',
  templateUrl: './about-personal.component.html',
  styleUrls: ['./about-personal.component.scss'],
  providers: [PhoneFormatterPipe],
})
export class AboutPersonalSettingsComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Output() updateSuccess: EventEmitter<User> = new EventEmitter<User>();
  aboutForm: FormGroup;
  loading: boolean;
  phoneMask = '012-345-6789';
  intlTelInput: any;
  currentUserPhoneNumber: string;

  constructor(
    @Inject(APP_PROPS)
    private readonly appProperties: ApplicationProperties,
    private readonly ref: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly sessionService: SessionService,
    private readonly phoneFormatter: PhoneFormatterPipe,
  ) {}

  @HostListener('countrychange', ['$event']) countryChange(e: MouseEvent) {
    e.stopPropagation();
    this.setPhoneMask();
    this.aboutForm.controls.phoneNumber.updateValueAndValidity();
  }

  async ngOnInit(): Promise<void> {
    this.initForm();
    await this.initIntlTelInput();
    this.populateFormValues();
    this.ref.detectChanges();
  }

  ngOnChanges(): void {
    this.populateFormValues();
  }

  initForm(): void {
    this.aboutForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      phoneNumber: [
        '',
        Validators.compose([Validators.required, this.phoneValidator()]),
      ],
      email: {
        value: '',
        disabled: true,
      },
    });
  }

  onPhoneChanged() {
    setTimeout(() => {
      this.setPhoneMask();
    }, 100);
  }

  setPhoneMask() {
    const input = document.querySelector('#phone');
    this.phoneMask = input?.getAttribute('placeholder');
    this.ref.detectChanges();
  }

  phoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      !this.intlTelInput || this.intlTelInput.isValidNumber()
        ? null
        : { phoneInvalid: { value: control.value } };
  }

  isControlHasError(
    form: FormGroup,
    controlName: string,
    validationType: string,
  ): boolean {
    const control = form.controls[controlName];
    if (!control) {
      return false;
    }

    return control.hasError(validationType);
  }

  async initIntlTelInput(): Promise<void> {
    return new Promise(async (resolve) => {
      const input = document.querySelector('#phone') as HTMLInputElement;
      input.classList.add('phone-input');
      this.intlTelInput = intlTelInput(input, {
        separateDialCode: true,
        utilsScript:
          'https://cdn.jsdelivr.net/npm/intl-tel-input@24.1.0/build/js/utils.js',
      });
      await this.intlTelInput.promise;
      resolve();
      this.ref.detectChanges();
    });
  }

  populateFormValues(): void {
    if (!this.aboutForm) {
      this.initForm();
    }
    if (this.user) {
      if (this.aboutForm.get('firstName').value !== this.user.firstName) {
        this.aboutForm.get('firstName').setValue(this.user.firstName);
      }
      if (this.aboutForm.get('lastName').value !== this.user.lastName) {
        this.aboutForm.get('lastName').setValue(this.user.lastName);
      }
      this.aboutForm.get('email').setValue(this.user.userEmail);
      const oldUserPhoneNumber = this.user.contact?.phoneNumber;
      if (
        oldUserPhoneNumber &&
        this.currentUserPhoneNumber !== oldUserPhoneNumber &&
        this.intlTelInput
      ) {
        this.intlTelInput.setNumber(oldUserPhoneNumber);
        if (this.intlTelInput.isValidNumber()) {
          const oldUserNumberWithoutCountryCode = oldUserPhoneNumber
            .replace(/\D/g, '')
            .replace(this.intlTelInput.getSelectedCountryData().dialCode, '');
          this.aboutForm
            .get('phoneNumber')
            .setValue(
              this.phoneFormatter.transform(
                oldUserNumberWithoutCountryCode,
                this.phoneMask,
              ),
            );
          this.currentUserPhoneNumber = `${this.user.contact.phoneNumber}`;
        } else {
          this.aboutForm.get('phoneNumber').setValue('');
        }
      }
    }
  }

  async updateUserAbout(): Promise<void> {
    this.loading = true;
    const updatedUser = this.createUserFromForm();

    try {
      const response = await this.userService.updateUser(updatedUser);
      this.onUpdateSuccess(response);
    } catch (err) {
      this.onUpdateFailure(err);
    }

    this.loading = false;
    this.ref.detectChanges();
  }

  createUserFromForm(): User {
    const updatedUser = { ...this.user };

    updatedUser.firstName = this.aboutForm.get('firstName').value;
    updatedUser.lastName = this.aboutForm.get('lastName').value;
    if (!updatedUser.contact) {
      updatedUser.contact = new Contact();
    }
    if (this.intlTelInput) {
      updatedUser.contact.countryCode =
        this.intlTelInput.getSelectedCountryData().iso2;
      updatedUser.contact.phoneNumber = this.intlTelInput.getNumber(
        IntlTelFormatEnum.INTERNATIONAL,
      );
    }

    return updatedUser;
  }

  get isCargoWallet(): boolean {
    return this.appProperties.name === 'cargowallet';
  }

  onUpdateSuccess(updatedUser: User): void {
    this.aboutForm.markAsPristine();
    this.updateSuccess.emit(updatedUser);
  }

  onUpdateFailure(err: any): void {
    Swal.fire('Something went wrong!', err.response.message, 'error');
  }
}
